
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import {
  Switch,
  Textarea,
  Tooltip,
  FormLabel, Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Image, useBreakpointValue, useColorModeValue, Tabs, TabList, Tab, TabPanels, TabPanel, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Input, Text, Heading, Icon, Badge, Box, SimpleGrid, Flex, Alert, AlertIcon, AlertTitle, AlertDescription
} from '@chakra-ui/react';
import { MdAdd, MdGroup, MdWork, MdChevronRight, MdLock } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedLayout } from '../layouts';
import { SelectedProjectMode, ViewportSourceDocument, selectedProjectSelector, currentUserState, UserPreferences, projectsState, pagesState, UserAllottedCredits, ProjectCommonElements } from '../state';
import { Project } from '../types';
import { InactiveProjectDialog } from '../components/projects/InactiveProjectDialog';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { useResetRecoilState } from 'recoil';
import { useProjectActions, useUserActions } from '../hooks';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

import { projectLibraries as libraries } from '../lib/project-modes/libraries';
import { projectTemplates as templates } from '../lib/project-modes/templates';
import { projectBootstrapTemplates as bootstrapTemplates } from '../lib/project-modes/bootstrap-templates';
import { projectBootstrapTemplatesAsLowerCaseStrings } from '../lib/project-modes/bootstrap-templates';
import AWS from 'aws-sdk';
import { languages } from '../lib/languages';

import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from '@chakra-ui/react'

import { SiBloglovin } from "react-icons/si";
import { BsArrowThroughHeartFill } from "react-icons/bs";
import { BsBagCheckFill } from "react-icons/bs";
import { BsGrid1X2Fill } from "react-icons/bs";
import { BsHeartPulseFill } from "react-icons/bs";
import { BsHouseFill } from "react-icons/bs";
import { BsHospitalFill } from "react-icons/bs";
import { BsLightbulbFill } from "react-icons/bs";
import { BsMegaphoneFill } from "react-icons/bs";
import { BsStars } from "react-icons/bs";
import { IoFastFood } from "react-icons/io5";
import { MdLocalHotel } from "react-icons/md";
import { BsAspectRatioFill } from "react-icons/bs";
import { BsAwardFill } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { BsBox2HeartFill } from "react-icons/bs";
import { BsChatDotsFill } from "react-icons/bs";
import { BsCircleFill } from "react-icons/bs";
import { BsCompassFill } from "react-icons/bs";
import { BsCupHotFill } from "react-icons/bs";
import { BsCreditCard2BackFill } from "react-icons/bs";
import { BsDiscFill } from "react-icons/bs";

import ColorSwatchPicker from '../components/engine/ColorSwatchPicker';

import FloatingComponents from '../components/FloatingComponents';

const cardStyle = {
  cursor: 'pointer',
};

export const ProjectListPage: React.FC = () => {


  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:98689dd4-f972-4f76-8aec-0b54f0cb2c08'
    }),
    httpOptions: {
      timeout: 600000 
    }
  });
  const lambda = new AWS.Lambda();

  let lambdaFunctionName : any

  if (window.location.hostname === 'app.sifo.ai') {
    lambdaFunctionName = 'my-app-api-production-poller'
  } else if (window.location.hostname === 'app.staging.sifo.ai') {
    lambdaFunctionName = 'my-app-api-staging-poller'
  }

  const [templateAutomation, setTemplateAutomation] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [autoPickTemplate, setAutoPickTemplate] = useState(false);

  const [automationTargetLanguage, setAutomationTargetLanguage] = useState<any>('');

  const handleAutomationTargetLanguageOnChange = (event: any) => {
    if (event.target.value) {
      setAutomationTargetLanguage(event.target.value);
    }
  }

  const shuffleArray = (array: any[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };
  const [shuffledTemplates, setShuffledTemplates] = useState<any[]>([]);

  useEffect(() => {
    setShuffledTemplates(shuffleArray(bootstrapTemplates));
  }, []);

  const invokeLambdaTranslation = async (payload: any) => {
    const lambdaPayload = {
      FunctionName: lambdaFunctionName,
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify(payload)
    };

    try {
      const lambdaResponse = await lambda.invoke(lambdaPayload).promise();
      const responsePayload = JSON.parse(lambdaResponse.Payload as any);

      if (responsePayload.statusCode === 200) {
        return JSON.parse(responsePayload.body);
      } else {
        throw new Error(responsePayload.body);
      }
    } catch (error) {
      console.error('Lambda invocation error:', error);
      throw error;
    }
  };

  const pickTemplateAutomatically = async () => {
    try {
      const response = await invokeLambdaTranslation({
        context: 'picktemplate',
        businessName,
        businessDescription
      });
      console.log('Automatically picked template:', response);
      return response; // Return the response directly
    } catch (error) {
      console.error('Error picking template:', error);
      throw error; // Rethrow the error to be caught in the calling function
    }
  };

  const debouncedSetBusinessName = debounce((value: string) => {
    setBusinessName(value);
  }, 250);

  const debouncedSetBusinessDescription = debounce((value: string) => {
    setBusinessDescription(value);
  }, 250);

  const handleBusinessNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    e.target.value = value; // Immediate update to input
    debouncedSetBusinessName(value); // Debounced state update
  };

  const handleBusinessDescriptionInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    e.target.value = value; // Immediate update to input
    debouncedSetBusinessDescription(value); // Debounced state update
  };

  useEffect(() => {
    return () => {
      debouncedSetBusinessName.cancel();
      debouncedSetBusinessDescription.cancel();
    };
  }, []);


  // Colors
  const [selectedThemeColor, setSelectedThemeColor] = useState(null);

  const handleThemeColorSelect = (color: any) => {
    setSelectedThemeColor(color);
  };

  // const handleBusinessNameInputChange = (e: any) => {
  //   setBusinessName(e.target.value);
  // }

  // const handleBusinessDescriptionInputChange = (e: any) => {
  //   setBusinessDescription(e.target.value);
  // }


  const isWideScreen = useBreakpointValue({ base: false, md: true });

  const defaultFaviconURL: string = "data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🔮</text></svg>";
  const MotionBox = motion(Box);

  const iconComponents: any = {
    SiBloglovin, BsArrowThroughHeartFill, BsBagCheckFill, BsGrid1X2Fill,
    BsHeartPulseFill, BsHouseFill, BsHospitalFill, BsLightbulbFill, BsMegaphoneFill,
    BsStars, IoFastFood, MdLocalHotel, BsAspectRatioFill, BsAwardFill, BsBellFill,
    BsBox2HeartFill, BsChatDotsFill, BsCircleFill, BsCompassFill, BsCupHotFill,
    BsCreditCard2BackFill, BsDiscFill
  };

  const { createProject, fetchProjectPages } = useProjectActions();
  const [selectedProjectMode, setSelectedProjectMode] = useRecoilState(SelectedProjectMode);
  // const [selectedTemplateId, setSelectedTemplateId] = useRecoilState<any>(SelectedTemplateId);

  const { isOpen: isCreateProjectOpen, onOpen: onCreateProjectOpen, onClose: onCreateProjectClose } = useDisclosure()
  const projectNameInputRef = useRef<HTMLInputElement>(null);

  const userPreferences = useRecoilValue(UserPreferences);

  type FormValues = {
    name: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const setViewportSourceDocument = useSetRecoilState(ViewportSourceDocument);
  const onSubmit = async ({ name }: { name: string }) => {
    let finalProjectMode = selectedProjectMode;
    console.log("selected b4", selectedProjectMode);

    let finalBusinessDescription = businessDescription;
    if (automationTargetLanguage) {
      finalBusinessDescription = `${businessDescription}\n//Return everything in ${automationTargetLanguage}`;
    }

    if (autoPickTemplate) {
      try {
        const response: any = await pickTemplateAutomatically();
        console.log("Response:", response);
        if (response && response.template) {
          finalProjectMode = response.template;
          setSelectedProjectMode(response.template);
        } else {
          throw new Error('No template name received from the server');
        }
      } catch (error) {
        console.error('Error picking template:', error);
      }
    }

    const fromTemplate = projectBootstrapTemplatesAsLowerCaseStrings.includes(finalProjectMode.toLowerCase());
    console.log("############## fromTemplate:", fromTemplate);

    await createProject({
      name,
      selectedProjectMode: finalProjectMode,
      favicon: defaultFaviconURL,
      fromTemplate,
      templateAutomation,
      businessName,
      businessDescription: finalBusinessDescription,
      themeColor: selectedThemeColor,
      onProjectCreation: true
    });
    onCreateProjectClose();
  };

  // const handleEnterKeyPress = (event: any) => {
  //   if (event.key === 'Enter') {
  //     handleSubmit(onSubmit);
  //   }
  // }

  // const resetChatBubbles = useResetRecoilState(ChatBubbles);
  const navigate = useNavigate();
  const [inactiveProjectId, setInactiveProjectId] = useState<string | null>(null);
  const setSelectedProject = useSetRecoilState(selectedProjectSelector);
  const projects = useRecoilValue(projectsState);
  const user = useRecoilValue(currentUserState);
  const resetPages = useResetRecoilState(pagesState);

  const setProjectCommonElements = useSetRecoilState(ProjectCommonElements);

  const userAllottedCredits = useRecoilValue(UserAllottedCredits);
  // const userPreferences = useRecoilValue(UserPreferences);
  // const { fetchUserPreferences } = useUserActions();

  // Clear viewport when no project is loaded.
  useEffect(() => {
    setViewportSourceDocument("")
  }, [])

  useEffect(() => {
    if (user?.plan_id === 'FREE_TRIAL') {
      setTemplateAutomation(true);
      setAutoPickTemplate(true);
    }
  }, [user?.plan_id]);

  const { isOpen: isUpgradeOpen, onOpen: onUpgradeOpen, onClose: onUpgradeClose } = useDisclosure();
  const { isOpen: isTemplateUpgradeOpen, onOpen: onTemplateUpgradeOpen, onClose: onTemplateUpgradeClose } = useDisclosure();

  const cancelRef = useRef<any | null>();

  const handleProjectCreateModalOpen = () => {
    if (userAllottedCredits?.projects_allowed !== 0) {
      onCreateProjectOpen();
    } else {
      // Display alert dialog, asking to upgrade..
      onUpgradeOpen();
    }
  }

  const onSelectProject = async (project: Project) => {
    setSelectedProject(project);
    setSelectedProjectMode(project.mode);

    // Set common project's common elements on project load.
    setProjectCommonElements(project.common_elements || []);
    resetPages();

    const fromTemplate = projectBootstrapTemplatesAsLowerCaseStrings.includes(project.mode.toLocaleLowerCase());
    fetchProjectPages({ project_id: project.id, fromTemplate });

    navigate(`/projects/${project.id}/dashboard`);
  };

  const radioColor = useColorModeValue('purple.400', 'purple.200');
  const textColor = useColorModeValue('white', 'gray.600');

  const handleSelectLibrary = (lib: any) => {
    if (lib.available_in.includes(user?.plan_id)) {
      setSelectedProjectMode(lib.name);
    } else {
      onCreateProjectClose();
      onUpgradeOpen();
    }
  }

  const handleSelectTemplate = (template: any) => {
    if (template.available_in.includes(user?.plan_id)) {
      setSelectedProjectMode(template.name);
    } else {
      onCreateProjectClose();
      onUpgradeOpen();
    }
  }
  useEffect(() => {
    if (shuffledTemplates.length > 0) {
      setSelectedProjectMode(shuffledTemplates[0].name);
    }
  }, [shuffledTemplates]);
  // const noPreviewModes: any = ['None', ...templates.map((t: any) => t.name)];
  // const noPreviewModes: any = ['None', ...projectBootstrapTemplatesAsLowerCaseStrings];
  const noPreviewModes: any = ['None'];

  const GetModeThumbnail = (lib: any, selected: string) => {
    return useColorModeValue(
      lib.name === selected ? lib.thumbnail[0] : lib.thumbnail[1],
      lib.name === selected ? lib.thumbnail[1] : lib.thumbnail[0]
    );
  }

  const ProjectCard = ({ project }: { project: Project }) => (
    <SoftCard
      key={project.id}
      style={{ ...cardStyle, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      onClick={() =>
        project.admin.has_active_subscription ? onSelectProject(project) : setInactiveProjectId(project.id)
      }
    >
      <Box p={4}>
        <Heading isTruncated fontWeight='semibold' as="h3" size="sm" mb={1} maxWidth="100px">{project.name}</Heading>
        <Text>{project.mode.toLowerCase()}</Text>
      </Box>
      <Box p={4} display="flex" flexDir="row" justifyContent='space-between' alignItems='center'>
        <Box display="flex" flexDir="row" alignItems='center' gap={2}>
          <Icon as={MdGroup} boxSize="20px" />
          <Text>{project.users?.length}</Text>
        </Box>
        <Box >
          <Badge variant={project.admin.has_active_subscription ? 'solid' : 'subtle'}>
            {project.admin.has_active_subscription ? 'Active' : 'Inactive'}
          </Badge>
        </Box>
      </Box>
      {user && !project.admin.has_active_subscription && (
        <InactiveProjectDialog
          isOpen={inactiveProjectId === project.id}
          onClose={() => setInactiveProjectId(null)}
          project={project}
          user={user}
        />
      )}
    </SoftCard>
  );

  return (
    <AuthenticatedLayout sidebar={false}>
      <Flex flexDirection='column' gap={3} w='full'>
        {/* <Alert variant="left-accent" status="info" colorScheme='purple'
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'start' }}
          justifyContent={{ base: 'center', md: 'start' }}
          height={{ base: '200px', md: 'auto' }}
        >
          <AlertIcon />
          <AlertDescription>
            The platform is in the release candidate phase and is actively evolving. Please expect occasional bugs, limitations, and adjustments along the way.
          </AlertDescription>
        </Alert> */}

        {/*<FloatingComponents />*/}


        <Flex justifyContent='space-between' gap={2} alignItems={'start'}>
          <PageHeading title="Projects" icon={MdWork} />
          {user?.plan_id && (
            <Box>
              <Stat>
                {/* <StatLabel>Projects left</StatLabel> */}
                {/* <StatHelpText>{userAllottedCredits?.projects_allowed || 'Unlimited'}</StatHelpText> */}

                <StatLabel>{userAllottedCredits?.projects_allowed === null ? "Unlimited" : userAllottedCredits?.projects_allowed}</StatLabel>
                <StatHelpText>Projects left</StatHelpText>
              </Stat>
            </Box>
          )}

        </Flex>
        <SimpleGrid h='full' gridTemplateColumns='repeat(auto-fill, minmax(200px, auto))' gridAutoRows={'200px'} gridGap={4} overflow='auto'>
          {/* <div style={{ height: '100%', display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(150px, auto))', gridAutoRows: '150px', gridGap: '20px', overflow: 'auto' }}> */}
          <SoftCard
            onClick={handleProjectCreateModalOpen}
            style={{
              ...cardStyle,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.8em'
            }}
          >
            <Icon boxSize="48px" as={MdAdd} />
            <Heading as="h3" size="sm" textAlign="center">
              Create Project
            </Heading>
          </SoftCard>

          <AlertDialog
            isOpen={isUpgradeOpen}
            leastDestructiveRef={cancelRef}
            onClose={onUpgradeClose}
            isCentered
            size="lg"
          >
            <AlertDialogOverlay>
              <AlertDialogContent
                boxShadow="lg"
                p={5}
                borderRadius="md"
                bg={useColorModeValue('white', 'gray.800')}
              >
                <AlertDialogHeader
                  fontSize="2xl"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  color={useColorModeValue('gray.800', 'white')}
                >
                  <Tooltip
                    label="Upgrade to unlock all features"
                    aria-label="Upgrade tooltip"
                    bg={useColorModeValue('gray.700', 'gray.200')}
                    color={useColorModeValue('white', 'gray.800')}
                  >
                    <Icon as={InfoOutlineIcon} color="purple.500" mr={3} />
                  </Tooltip>
                  Upgrade
                </AlertDialogHeader>

                <AlertDialogBody
                  fontSize="lg"
                  mt={4}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  color={useColorModeValue('gray.700', 'gray.200')}
                >
                  <MotionBox
                    animate={{ rotate: [0, 360] }}
                    transition={{ duration: 2, ease: "easeInOut", repeat: Infinity }}
                    mb={6}
                  >
                    <Icon
                      as={InfoOutlineIcon}
                      w={16}
                      h={16}
                      color={useColorModeValue('purple.500', 'purple.400')}
                    />
                  </MotionBox>
                  <Text textAlign="center">
                    <Text
                      as="span"
                      fontWeight="bold"
                      color={useColorModeValue('gray.800', 'white')}
                    >
                      This action is not available in your current plan.
                    </Text>{" "}
                    Upgrade now to unlock it and get the most out of SIFO's full suite of features.
                  </Text>
                </AlertDialogBody>

                <AlertDialogFooter justifyContent="center" mt={6}>
                  <Button
                    size="md"
                    variant="outline"
                    ref={cancelRef}
                    onClick={onUpgradeClose}
                    mr={3}
                    color={useColorModeValue('gray.800', 'white')}
                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                    _hover={{
                      bg: useColorModeValue('gray.100', 'gray.700')
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    colorScheme="purple"
                    onClick={() => navigate('/upgrade')}
                    variant='outline'
                    _hover={{
                      boxShadow: useColorModeValue(
                        '0 0 10px rgba(128, 90, 213, 0.5)',
                        '0 0 10px rgba(128, 90, 213, 0.8)'
                      )
                    }}
                  >
                    Unlock Now
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          <AlertDialog
            isOpen={isTemplateUpgradeOpen}
            leastDestructiveRef={cancelRef}
            onClose={onTemplateUpgradeClose}
            isCentered
            size="lg"
          >
            <AlertDialogOverlay>
              <AlertDialogContent
                boxShadow="lg"
                p={5}
                borderRadius="md"
                bg={useColorModeValue('white', 'gray.800')}
              >
                <AlertDialogHeader
                  fontSize="2xl"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  color={useColorModeValue('gray.800', 'white')}
                >
                  <Tooltip
                    label="Upgrade to unlock all features"
                    aria-label="Upgrade tooltip"
                    bg={useColorModeValue('gray.700', 'gray.200')}
                    color={useColorModeValue('white', 'gray.800')}
                  >
                    <Icon
                      as={InfoOutlineIcon}
                      color={useColorModeValue('purple.600', 'purple.500')}
                      mr={3}
                    />
                  </Tooltip>
                  Upgrade
                </AlertDialogHeader>

                <AlertDialogBody
                  fontSize="lg"
                  mt={4}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  color={useColorModeValue('gray.700', 'gray.200')}
                >
                  <MotionBox
                    animate={{ rotate: [0, 360] }}
                    transition={{ duration: 2, ease: "easeInOut", repeat: Infinity }}
                    mb={6}
                  >
                    <Icon
                      as={InfoOutlineIcon}
                      w={16}
                      h={16}
                      color={useColorModeValue('purple.500', 'purple.400')}
                    />
                  </MotionBox>
                  <Text textAlign="center">
                    <Text
                      as="span"
                      fontWeight="bold"
                      color={useColorModeValue('gray.800', 'white')}
                    >
                      This action is not available in your current plan.
                    </Text>{" "}
                    Upgrade now to unlock 100s of templates and manually pick the one you want.
                  </Text>
                </AlertDialogBody>

                <AlertDialogFooter justifyContent="center" mt={6}>
                  <Button
                    size="md"
                    variant="outline"
                    ref={cancelRef}
                    onClick={onTemplateUpgradeClose}
                    mr={3}
                    color={useColorModeValue('gray.800', 'white')}
                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                    _hover={{
                      bg: useColorModeValue('gray.100', 'gray.700')
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    colorScheme="purple"
                    onClick={() => navigate('/upgrade')}
                    variant='outline'
                    _hover={{
                      boxShadow: useColorModeValue(
                        '0 0 10px rgba(128, 90, 213, 0.5)',
                        '0 0 10px rgba(128, 90, 213, 0.8)'
                      )
                    }}
                  >
                    Unlock Now
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          {/* <Modal isOpen={isCreateProjectOpen} onClose={onCreateProjectClose} initialFocusRef={projectNameInputRef} isCentered> */}
          <Modal scrollBehavior="inside" size={{ base: "full", md: !noPreviewModes.includes(selectedProjectMode) ? '6xl' : 'md' }} isOpen={isCreateProjectOpen} onClose={onCreateProjectClose} initialFocusRef={projectNameInputRef} isCentered >
            {/* <Modal scrollBehavior="inside" size="full" isOpen={isCreateProjectOpen} onClose={onCreateProjectClose} initialFocusRef={projectNameInputRef} isCentered > */}
            <ModalOverlay overflow="hidden" />
            <ModalContent>
              {/* <ModalContent maxHeight={'600px'}> */}
              <ModalHeader>Create Project</ModalHeader>
              <ModalCloseButton />
              {/* <Flex> */}

              <ModalBody>

                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* <SimpleGrid bg="orange" gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }} gridTemplateRows={{ base: "1fr 1fr", md: "1fr" }}> */}
                  <Flex flexDir={{ base: "column", md: "row" }} justifyContent={'space-between'} gap={6}>
                    <Flex flexDir={'column'} gap={2} flexGrow={1} maxW={!noPreviewModes.includes(selectedProjectMode) ? "50%" : "100%"}>
                      <Text fontSize="md">
                        Let's start with a name for your project
                      </Text>
                      {/* <Input defaultValue={'Untitled'} size={'md'} onKeyPress={handleEnterKeyPress} placeholder="Enter your project name" {...register('name', { required: true })} /> */}
                      <Input defaultValue={'Untitled project'} size={'md'} placeholder="Enter your project name" {...register('name', { required: true })} />
                      {errors?.name && <Text fontSize={'sm'}>Name is required</Text>}

                      <Tabs variant='enclosed-colored' colorScheme='purple' isFitted>
                        <TabList>
                          <Tooltip label='Template mode: Automated & Edit a template.' hasArrow isDisabled={!userPreferences?.display_tooltips || false}>
                            <Tab>Templates</Tab>
                          </Tooltip>
                          <Tooltip label='Creative mode: Create anything.' hasArrow isDisabled={!userPreferences?.display_tooltips || false}>
                            <Tab>Creative</Tab>
                          </Tooltip>
                          <Tooltip label='Guided mode: A guided experience, focused AI.' hasArrow isDisabled={!userPreferences?.display_tooltips || false}>
                            <Tab>Guided</Tab>
                          </Tooltip>
                        </TabList>
                        <TabPanels>
                          <TabPanel py={4} px={0}>
                            <Flex flexDir={'column'} gap={4}>

                              <SimpleGrid
                                height={{ base: "240px", md: "400px" }}
                                gridTemplateColumns={{ base: "1fr 1fr", md: "1fr 1fr" }}
                                gap={{ base: 2, md: 4 }}
                                overflowY='auto'
                                css={{
                                  "::-webkit-scrollbar": { display: "none" },
                                  transition: "opacity 0.3s, filter 0.3s",
                                }}
                                opacity={autoPickTemplate ? 0.5 : 1}
                                pointerEvents={autoPickTemplate ? 'none' : 'auto'}
                                filter={autoPickTemplate ? 'blur(4px)' : 'none'}
                                position="relative"
                              >
                                {shuffledTemplates.map((template: any, index: number) => {
                                  return (
                                    <Flex
                                      bg={template.name === selectedProjectMode ? radioColor : ''}
                                      key={index}
                                      onClick={() => handleSelectTemplate(template)}
                                      cursor='pointer'
                                      borderWidth='1px'
                                      borderRadius='md'
                                      boxShadow='md'
                                      p={{ base: 2, md: 3 }}
                                      title={template.display_name}
                                      flexDir="column"
                                      opacity={!template.available_in.includes(user?.plan_id) ? 0.5 : 1}
                                      gap={2}
                                      height={{ base: "150px", md: "180px" }}
                                      position="relative"
                                    >
                                      {!template.available_in.includes(user?.plan_id) && (
                                        <Box position="absolute" top={2} right={2}>
                                          <MdLock fontSize={16} />
                                        </Box>
                                      )}

                                      <Box flex="1" minHeight="0">
                                        <Image
                                          src={template.thumbnail}
                                          w="full"
                                          h="100%"
                                          objectFit="cover"
                                          borderRadius="md"
                                        />
                                      </Box>

                                      <Box
                                        p={2}
                                        width="full"
                                        minHeight="40px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Text
                                          wordBreak={'break-word'}
                                          noOfLines={2}
                                          fontSize={{ base: "xs", md: "sm" }}
                                          fontWeight="medium"
                                          textAlign="center"
                                        >
                                          {template.display_name}
                                        </Text>
                                      </Box>
                                    </Flex>
                                  )
                                })}

                                {autoPickTemplate && (
                                  <Box
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    right="0"
                                    bottom="0"
                                    bg="transparent"
                                    zIndex="1"
                                  />
                                )}
                              </SimpleGrid>
                              {/* </Flex> */}


                            </Flex>
                          </TabPanel>
                          <TabPanel py={4} px={0}>
                            <Flex flexDir={'column'} gap={4}>
                              {/* <Text fontSize="md">
                                Pick a flavor to power your creation.
                              </Text> */}

                              <SimpleGrid h='full' gridTemplateColumns='repeat(auto-fill, minmax(85px, auto))' gridAutoRows={'85px'}
                                height={
                                  {
                                    base: !noPreviewModes.includes(selectedProjectMode) ? "240px" : "100%",
                                    md: "240px"
                                  }
                                }
                                gridGap={2} overflow='auto' css={{ "::-webkit-scrollbar": { display: "none" } }}>
                                {libraries.map((lib: any, index: number) => {
                                  return (
                                    <Tooltip key={index} label={lib.tooltip} hasArrow isDisabled={!userPreferences?.display_tooltips || false}>
                                      <Flex
                                        opacity={!lib.available_in.includes(user?.plan_id) ? 0.5 : 1}
                                        bg={lib.name === selectedProjectMode ? radioColor : ''} key={index} onClick={() => handleSelectLibrary(lib)}
                                        flexDir={'column'}
                                        gap={3}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        cursor='pointer'
                                        borderWidth='1px'
                                        borderRadius='md'
                                        boxShadow='md'
                                        p={2}
                                      >

                                        {lib.thumbnail && (
                                          <Image src={GetModeThumbnail(lib, selectedProjectMode)} boxSize='28px' fit='contain' />
                                        )}

                                        <Flex gap={2} alignItems="center">
                                          <Text fontSize={'xs'} color={lib.name === selectedProjectMode ? textColor : ''}>
                                            {lib.name}
                                          </Text>

                                          {!lib.available_in.includes(user?.plan_id) && (
                                            <Box>
                                              <MdLock fontSize={12} />
                                            </Box>
                                          )}
                                        </Flex>

                                      </Flex>
                                    </Tooltip>
                                  )
                                })}
                              </SimpleGrid>

                            </Flex>
                          </TabPanel>
                          <TabPanel py={4} px={0}>
                            <Flex flexDir={'column'} gap={4}>
                              {/* <Text fontSize="md">
                                You can get faster results, with a more focused AI.
                              </Text> */}
                              <Flex flexDir='column' height='240px' gap={2} overflowY='auto' css={{ "::-webkit-scrollbar": { display: "none" } }}>
                                {templates.map((template: any, index: number) => {
                                  const IconComponent = iconComponents[template.thumbnail];
                                  if (!IconComponent) return;

                                  return (
                                    <Flex
                                      bg={template.name === selectedProjectMode ? radioColor : ''} key={index} onClick={() => handleSelectTemplate(template)}
                                      // bg={template.name === selectedProjectMode ? radioColor : ''} key={index} onClick={() => setSelectedProjectMode(template.name)}
                                      justifyContent={'space-between'}
                                      alignItems={'center'}
                                      cursor='pointer'
                                      borderWidth='1px'
                                      borderRadius='md'
                                      boxShadow='md'
                                      p={4}
                                      title={template.name}
                                    >
                                      <Flex opacity={!template.available_in.includes(user?.plan_id) ? 0.5 : 1} alignItems='center' gap={3}>
                                        <IconComponent />
                                        {/* <Text wordBreak={'normal'} noOfLines={2} textShadow={template.name === selectedProjectMode ? "1px 1px 1px rgba(0, 0, 0, 0.3)" : ""}> */}
                                        <Text wordBreak={'normal'} noOfLines={2}>
                                          {template.name}
                                        </Text>

                                        {!template.available_in.includes(user?.plan_id) && (
                                          <Box>
                                            <MdLock fontSize={12} />
                                          </Box>
                                        )}
                                      </Flex>
                                      <MdChevronRight />
                                    </Flex>
                                  )
                                })}
                              </Flex>

                              {/* <SimpleGrid h={{ base: "100%", md: "200px" }} gridTemplateColumns='repeat(auto-fill, minmax(100px, auto))' gridAutoRows={'100px'} gridGap={2} overflowY='auto'>
                                {templates.map((template: any, index: number) => {
                                  return (
                                    <Flex
                                      bg={template.name === selectedProjectMode ? radioColor : ''} key={index} onClick={() => setSelectedProjectMode(template.name)}
                                      justifyContent={'center'}
                                      alignItems={'center'}
                                      cursor='pointer'
                                      borderWidth='1px'
                                      borderRadius='md'
                                      boxShadow='md'
                                      p={4}
                                      title={template.name}
                                    >
                                      <Text wordBreak={'normal'} noOfLines={2} color={template.name === selectedProjectMode ? textColor : ''}>
                                        {template.name}
                                      </Text>
                                    </Flex>
                                  )
                                })}
                              </SimpleGrid> */}

                            </Flex>
                          </TabPanel>
                        </TabPanels>
                      </Tabs>
                    </Flex>

                    {!noPreviewModes.includes(selectedProjectMode) && (
                      <Flex flexDir={'column'} px={4} gap={4} width={{ base: "100%", md: "50%" }}>

                        {/* ------------------- START ------------------- */}
                        <>
                          {libraries.map(l => l.name).includes(selectedProjectMode) && (
                            <>
                              {libraries.map((lib: any, index: number) => {
                                if (lib.name === selectedProjectMode) {
                                  return (
                                    <Box key={index}>
                                      <Image objectFit={'cover'} src={lib.showcase} h={'400px'} />
                                    </Box>
                                  )
                                }
                              })}
                            </>
                          )}

                          {templates.map(t => t.name).includes(selectedProjectMode) && (
                            <>
                              {templates.map((template: any, index: number) => {
                                if (template.name === selectedProjectMode) {
                                  return (
                                    <Flex px={4} flexDir='column' gap={6}>
                                      <Flex alignItems='flex-end' gap={6}>
                                        <Image src='/illustrations/templates-guide.png' width={14} />
                                        <Heading size={'lg'}>Helpful tips to get the most out of templates.</Heading>
                                      </Flex>

                                      <Flex flexDir='column' gap={4}>
                                        <Heading size={'md'}>Keep it short.</Heading>
                                        <Text fontSize="md">
                                          With templates, it's best to keep it short, and let it do its thing. Give it the least amount of information, and it will build you something stunning in seconds.
                                        </Text>
                                        <SimpleGrid gridTemplateColumns="1fr 1fr" gap={4}>
                                          <Text fontSize="xs">
                                            e.g. "I have a dentist practice in downtown Montreal, please help me create a landing page."
                                          </Text>
                                          <Text fontSize="xs">
                                            e.g. "My restaurant is named Joe's Pizza, I need a website."
                                          </Text>
                                          <Text fontSize="xs">
                                            e.g. "We're a law firm called Equinox Law."
                                          </Text>
                                        </SimpleGrid>
                                      </Flex>
                                    </Flex>
                                  )
                                }
                              })}
                            </>
                          )}

                          {bootstrapTemplates.map(bt => bt.name).includes(selectedProjectMode) && (
                            <>
                              {bootstrapTemplates.map((btemplate: any, index: number) => {
                                if (btemplate.name === selectedProjectMode) {
                                  return (
                                    <Flex key={index} flexDir="column" gap={4}>
                                      {/* Colors. */}
                                      <Text>Pick theme color</Text>
                                      <ColorSwatchPicker onColorSelect={handleThemeColorSelect} />

                                      <Flex gap={2} alignItems="center">
                                        <Switch
                                          isChecked={templateAutomation}
                                          onChange={() => {
                                            if (user?.plan_id === 'FREE_TRIAL') {
                                              // Temporarily change value
                                              setTemplateAutomation(false);
                                              // Reset after brief delay
                                              setTimeout(() => {
                                                setTemplateAutomation(true);
                                                onTemplateUpgradeOpen();
                                              }, 200);
                                            } else {
                                              const newValue = !templateAutomation;
                                              setTemplateAutomation(newValue);
                                              if (!newValue) {
                                                setAutoPickTemplate(false);
                                              }
                                            }
                                          }}
                                          size='sm'
                                          colorScheme='purple'
                                        />
                                        <Text>Let us spin up a website for you</Text>
                                      </Flex>

                                      <Flex gap={2} alignItems="center">
                                        <Switch
                                          isChecked={autoPickTemplate}
                                          onChange={() => {
                                            if (user?.plan_id === 'FREE_TRIAL') {
                                              // Temporarily change value
                                              setAutoPickTemplate(false);
                                              // Reset after brief delay
                                              setTimeout(() => {
                                                setAutoPickTemplate(true);
                                                onTemplateUpgradeOpen();
                                              }, 200);
                                            } else {
                                              const newValue = !autoPickTemplate;
                                              setAutoPickTemplate(newValue);
                                              if (newValue) {
                                                setTemplateAutomation(true);
                                              }
                                            }
                                          }}
                                          size='sm'
                                          colorScheme='purple'
                                        />
                                        <Text>Let SIFO pick the perfect template</Text>
                                      </Flex>
                                      <Input
                                        defaultValue={businessName}
                                        onChange={handleBusinessNameInputChange}
                                        isDisabled={!templateAutomation}
                                        placeholder="Enter your business name e.g. Acme"
                                      />
                                      <Textarea
                                        defaultValue={businessDescription}
                                        onChange={handleBusinessDescriptionInputChange}
                                        isDisabled={!templateAutomation}
                                        height="250px"
                                        placeholder='Enter your business description e.g. We specialize in providing software and hardware solutions to businesses.'
                                      />

                                      <FormLabel>Language</FormLabel>
                                      <Select
                                        mt={1}
                                        size="sm"
                                        onChange={handleAutomationTargetLanguageOnChange}
                                        value={automationTargetLanguage}
                                        isDisabled={!templateAutomation}
                                      >
                                        {languages && languages.map((language: any, index: number) => (
                                          <option key={index} value={language.name}>{language.name}</option>
                                        ))}
                                      </Select>

                                    </Flex>
                                  )
                                }
                              })}
                            </>
                          )}

                        </>

                      </Flex>
                    )}



                    {/* ------------------- END ------------------- */}
                  </Flex>
                  {/* </SimpleGrid> */}
                </form>

              </ModalBody>
              {/* </Flex> */}

              <ModalFooter>
                <Button onClick={handleSubmit(onSubmit)} size="sm" rightIcon={<MdChevronRight />} colorScheme='purple'>
                  Continue
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {user && projects && projects.map((project) => <ProjectCard key={project.id} project={project} />)}
          {/* </div> */}
        </SimpleGrid>
      </Flex>
    </AuthenticatedLayout >
  );
};