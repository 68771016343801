import React, { useState } from 'react';
import { Flex, Heading, Button, Text } from '@chakra-ui/react';
import { SoftCard } from '../../components/generic/SoftCard';
import { MdColorLens } from "react-icons/md";
import ColorSwatchPicker from "./ColorSwatchPicker";
import '../../lib/anim.css';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ViewportSourceDocument, pagesState, ProjectColorMap, ProjectColorPalette, CSSCode, ApplyThemeColor } from '../../state';
import SingleColor from './SingleColor';
// import { extractColorRules } from "./color-palette/extract-color-rules";
// import { extractColorPalette, replaceColorPalette } from "./color-palette/palette";
// import { parseCss, writeCSSFromRules } from "./css-tools";
// import { useOpenaiActions } from '../../hooks';
import { useProjectActions } from '../../hooks';

import { extractColorPalette, overwriteColors } from './color-palette/stylesheets-utils';
import { createThemeColorMapping } from './color-palette/mapping';

export const Colors: React.FC<any> = () => {
  const { parseWithIframe, updateProjectPages } = useProjectActions();
  const [selectedThemeColor, setSelectedThemeColor] = useState('rgba(255, 0, 0, 1)');
  const [pages, setPages] = useRecoilState(pagesState);
  const setViewportSourceDocument = useSetRecoilState(ViewportSourceDocument);
  
  // const { getColorPalette } = useOpenaiActions();
  // const [parsedCssCode, setParsedCssCode] = useState<any>(null);
  // const [projectColorMap, setProjectColorMap] = useRecoilState(ProjectColorMap);
  // const projectColorPalette = useRecoilValue(ProjectColorPalette);
  // const [cssCode, setCssCode] = useRecoilState(CSSCode);
  // const setApplyThemeColor = useSetRecoilState<any>(ApplyThemeColor);

  const handleColorSelect = (color: any) => {
    setSelectedThemeColor(color);
    // Do something with the selected color
    // console.log('Selected color:', color);
  };

  // const handleDetectProjectColors = () => {
  //   const parsedStyles = parseCss(cssCode);
  //   const colorRules = extractColorRules(parsedStyles);
  //   const colorPalette = extractColorPalette(colorRules);

  //   setProjectColorMap(colorPalette);
  //   setParsedCssCode(parsedStyles);
  // }

  const handleApplyColors = async () => {
    const themeColor = selectedThemeColor;

    let modifiedPages: any;

    const domParser = new DOMParser();

    if (themeColor) {
      modifiedPages = await Promise.all(pages.map(async (page: any) => {
        const newPage = { ...page };
    
        const viewport = await parseWithIframe(newPage.content);
    
        const { originalToNormalized, normalizedColors } = extractColorPalette(viewport);
        const colorMapping = createThemeColorMapping(themeColor, normalizedColors);

        const cleanViewport = domParser.parseFromString(newPage.content, "text/html");
        overwriteColors(colorMapping, viewport, cleanViewport, originalToNormalized);
    
        newPage.content = cleanViewport.documentElement.outerHTML;
        return newPage;
      }));
    } else {
      // Remove override stylesheet
      modifiedPages = await Promise.all(pages.map(async (page: any) => {
        const newPage = { ...page };
    
        const cleanViewport = domParser.parseFromString(newPage.content, "text/html");

        const overrideStylesheet = cleanViewport.getElementById("color-override-stylesheet");
        if (overrideStylesheet) {
          overrideStylesheet.remove();
        }
    
        newPage.content = cleanViewport.documentElement.outerHTML;
        return newPage;
      }));
    }
    
  
    setPages(modifiedPages);
    setViewportSourceDocument(modifiedPages[0].content);
    // updateProjectPages(modifiedPages);

    const updateDatabasePages = modifiedPages.map((page: any) => {
      return {
        id: page.id,
        content: page.content
      }
    }) 

    updateProjectPages(updateDatabasePages);

  }

  // Legacy implementation, no longer needed I believe.
  // const newColorPalette = await getColorPalette(projectColorMap, selectedThemeColor);
  // console.log("@@@ newColorPalette: ", newColorPalette);

  // const replacedColors = replaceColorPalette(parsedCssCode, newColorPalette);
  // const cssString = writeCSSFromRules(replacedColors);
  // console.log("@@@ cssString: ", cssString);

  // setApplyThemeColor(cssString);

  return (
    <SoftCard h='100%' minH='0' flex={1}>
      <Flex className='zoom' flexDir='column' gap={4} h='full'>
        <Flex alignItems='end' gap={2}>
          <MdColorLens />
          <Heading as='h5' size='sm'>
            Colors
          </Heading>
        </Flex>
        <Flex flexDir='column' gap={2} overflowY='auto' css={{ "::-webkit-scrollbar": { display: "none" } }}>

          {/* <Button size="xs" colorScheme="purple" onClick={handleDetectProjectColors}>Detect colors</Button> */}

          {/* Render detected colors, there is no longer a need for that. */}

          {/* <Flex flexWrap="wrap" gap={2}>
            {projectColorPalette && projectColorPalette.map((color: any, index: number) => (
              <SingleColor key={index} color={color} />
            ))}
          </Flex> */}


          <Text>Pick theme color</Text>
          <ColorSwatchPicker onColorSelect={handleColorSelect} />
          {/* <p>Selected color: {selectedThemeColor}</p> */}
        </Flex>

        <Button justifySelf="flex-end" size="xs" colorScheme="purple" onClick={handleApplyColors}>Apply</Button>
      </Flex>
    </SoftCard>
  );
};
