import { cssjs } from 'jotform-css.js';

export const parseCss = (css: string) => {
  const parser = new cssjs();
  const parsedOutput: any = parser.parseCSS(css);
  return parsedOutput;
}

export const getIDsRecursively = (element: any) => {
  const IDs: any = [];

  const traverse = (node: any) => {
    if (node && node.nodeType === Node.ELEMENT_NODE) {
      if (node.id) {
        IDs.push(`#${node.id}`);
      }

      const children = node.children;
      for (let i = 0; i < children.length; i++) {
        traverse(children[i]);
      }
    }
  }

  traverse(element);
  return IDs;
}

export const getClassesRecursively = (element: any) => {
  const classes: any = [];

  const traverse = (node: any) => {
    if (node && node.nodeType === Node.ELEMENT_NODE) {
      if (node.getAttribute("class")) {
        classes.push(`.${node.getAttribute("class")}`);
      }

      const children = node.children;
      for (let i = 0; i < children.length; i++) {
        traverse(children[i]);
      }
    }
  }

  traverse(element);
  return classes;
}

export const getTagsRecursively = (element: any) => {
  const tags: any = [];

  const traverse = (node: any) => {
    if (node && node.nodeType === Node.ELEMENT_NODE) {
      if (node.tagName.toLowerCase()) {
        tags.push(node.tagName.toLowerCase());
      }

      const children = node.children;
      for (let i = 0; i < children.length; i++) {
        traverse(children[i]);
      }
    }
  }

  traverse(element);
  return tags;
}

export const extractCSSRulesBySelectors = (tree: any, selectors: any) => {
  let found: any = [];

  tree.forEach((trunk: any) => {
    if (selectors.some((selector: any) => trunk.selector.includes(selector))) {
      found.push(trunk);
    } else if (trunk.type === 'media') {
      trunk.subStyles = trunk.subStyles.filter((subStyle: any) => selectors.some((selector: any) => subStyle.selector.includes(selector)));
      found.push(trunk);
    } else if (trunk.type === 'keyframes') {
      found.push(trunk);
    }
  });

  return found;
}


// export const extractCSSRulesBySelectorsNoKeyfranes = (tree: any, selectors: any) => {
//   let found: any = [];
//   const selectorRegexes = selectors.map((selector: any) => new RegExp(`^${selector}$`));

//   tree.forEach((trunk: any) => {
//     if (trunk.selector && selectorRegexes.some((regex: any) => regex.test(trunk.selector))) {
//       found.push(trunk);
//     } else if (trunk.type === 'media') {
//       trunk.subStyles = trunk.subStyles.filter((subStyle: any) => 
//         selectorRegexes.some((regex: any) => regex.test(subStyle.selector))
//       );
//       if (trunk.subStyles.length > 0) {
//         found.push(trunk);
//       }
//     }
//   });

//   return found;
// }


export const extractCSSRulesBySelectorsNoKeyfranes = (tree: any, selectors: any) => {
  let found: any = [];

  tree.forEach((trunk: any) => {
    if (selectors.some((selector: any) => trunk.selector.includes(selector))) {
      found.push(trunk);
    } else if (trunk.type === 'media') {
      trunk.subStyles = trunk.subStyles.filter((subStyle: any) => selectors.some((selector: any) => subStyle.selector.includes(selector)));
      found.push(trunk);
    }
  });

  return found;
}

export const deleteCSSRulesBySelectors = (tree: any, selectors: any) => {
  return tree.filter((trunk: any) => {
    if (trunk.type === 'media') trunk.subStyles = trunk.subStyles.filter((subStyle: any) => !selectors.some((selector: any) => subStyle.selector.includes(selector)));
    return !selectors.some((selector: any) => trunk.selector.includes(selector))
  });
};

export const writeCSSFromRules = (tree: any, indentation: number = 0): string => {
  if (!tree || !Array.isArray(tree)) {
    return '';
  }

  const css = tree.map((trunk: any) => {
    if (!trunk.type) {
      const selector = trunk.selector;
      
      if (trunk && trunk.rules && trunk.rules.length > 0) {
        const rules = trunk.rules
          .map((rule: any) => {
            const { directive, value } = rule;
            return `\t${directive}: ${value};`;
          })
          .join('\n');
  
        // Check if the rules block is empty and return nothing if it is
        if (rules.trim() === '') {
          return '';
        }
  
        return `${' '.repeat(indentation)}${selector} {
    ${rules}
    ${' '.repeat(indentation)}}
    `.trim();
      }
    } else if (trunk.type === 'media') {
      const mediaSelector = trunk.selector;
      const subStyles = writeCSSFromRules(trunk.subStyles, indentation + 2);

      // Check if the subStyles block is empty and return nothing if it is
      if (subStyles.trim() === '') {
        return '';
      }

      return `
  ${' '.repeat(indentation)}${mediaSelector} {
  ${subStyles}
  ${' '.repeat(indentation)}}
  `.trim();
    } else if (trunk.type === 'keyframes') {
      return trunk.styles;
    } else {
      return '';
    }
  });

  // Filter out any empty CSS blocks
  return css.filter((block: any) => {
    if (block && block.trim() !== '') {
      return true;
    }
  }).join('\n\n');
};

// export const writeCSSFromRules = (tree: any, indentation: number = 0) => {
//   const css = tree.map((trunk: any) => {
//     if (!trunk.type) {
//       const selector = trunk.selector;
//       const rules = trunk.rules
//         .map((rule: any) => {
//           const { directive, value } = rule;
//           return `\t${directive}: ${value};`;
//         })
//         .join('\n');

//       // Check if the rules block is empty and return nothing if it is
//       if (rules.trim() === '') {
//         return '';
//       }

//       return `${' '.repeat(indentation)}${selector} {
//   ${rules}
//   ${' '.repeat(indentation)}}
//   `.trim();
//     } else if (trunk.type === 'media') {
//       const mediaSelector = trunk.selector;
//       const subStyles = writeCSSFromRules(trunk.subStyles, indentation + 2);

//       // Check if the subStyles block is empty and return nothing if it is
//       if (subStyles.trim() === '') {
//         return '';
//       }

//       return `
//   ${' '.repeat(indentation)}${mediaSelector} {
//   ${subStyles}
//   ${' '.repeat(indentation)}}
//   `.trim();
//     } else if (trunk.type === 'keyframes') {
//       return trunk.styles;
//     } else {
//       return '';
//     }
//   });

//   // Filter out any empty CSS blocks
//   return css.filter((block: any) => block.trim() !== '').join('\n\n');
// };


// export const writeCSSFromRules = (tree: any, indentation: number = 0) => {
//   const css = tree
//     .map((trunk: any) => {
//       if (!trunk.type) {
//         const { selector, rules } = trunk;
//         const ruleLines = rules
//           .map(({ directive, value }: any) => `  ${directive}: ${value};`)
//           .join('\n');

//         // Only return CSS block if there are rules
//         return ruleLines
//           ? `${' '.repeat(indentation)}${selector} {\n${ruleLines}\n${' '.repeat(indentation)}}`
//           : '';
//       } else if (trunk.type === 'media') {
//         const mediaSelector = trunk.selector;
//         const subStyles = writeCSSFromRules(trunk.subStyles, indentation + 2);

//         // Only return media block if there are sub-styles
//         return subStyles
//           ? `${' '.repeat(indentation)}${mediaSelector} {\n${subStyles}\n${' '.repeat(indentation)}}`
//           : '';
//       } else if (trunk.type === 'keyframes') {
//         return trunk.styles;
//       } else {
//         return '';
//       }
//     })
//     .filter(Boolean) // Remove empty strings
//     .join('\n\n');

//   return css;
// };


// export const writeCSSFromRules = (tree: any, indentation: number = 0) => {
//   const css = tree.map((trunk: any) => {
//     if (!trunk.type) {
//       const selector = trunk.selector;
//       const rules = trunk.rules
//         .map((rule: any) => {
//           const { directive, value } = rule;
//           return `\t${directive}: ${value};`;
//         })
//         .join('\n');

//       return `${' '.repeat(indentation)}${selector} {
//   ${rules}
//   ${' '.repeat(indentation)}}
//   `.trim();
//     } else if (trunk.type === 'media') {
//       const mediaSelector = trunk.selector;
//       const subStyles = writeCSSFromRules(trunk.subStyles, indentation + 2);

//       return `
//   ${' '.repeat(indentation)}${mediaSelector} {
//   ${subStyles}
//   ${' '.repeat(indentation)}}
//   `.trim();s
//     } else if (trunk.type === 'keyframes') {
//       return trunk.styles;
//     } else {
//       return '';
//     }
//   });

//   return css.join('\n\n');
// };

export function normalizeSelector(selector: string): string {
  let normalizedSelector = selector;

  if (selector.startsWith('#') || selector.startsWith('.')) {
      const prefix = selector[0];
      const rest = selector.slice(1);

      if (/^\d/.test(rest)) {
          normalizedSelector = `${prefix}_${rest}`;
      }
  } else {
      if (/^\d/.test(selector)) {
          normalizedSelector = `_${selector}`;
      }
  }

  return normalizedSelector;
}

// export function fixCssSelectors(css: string): string {
//     const regex = /([.#])(\d[\w-]*)/g;
    
//     const replaceSelector = (match: string, symbol: string, selector: string) => {
//         return `${symbol}_${selector}`;
//     };

//     return css.replace(regex, replaceSelector);
// }

export function fixCssSelectors(css: string) {
    const lines = css.split('\n');

    const selectorRegex = /(^|\s|,|{)([.#]?)(\d[\w-]*)/g;
    for (let i = 0; i < lines.length; i++) {
        const line = lines[i];

        if (!/;|@media/.test(line)) {
            lines[i] = line.replace(selectorRegex, (match, p1, p2, p3) => {
                if (/^[0-9]/.test(p3)) {
                    return `${p1}${p2}_${p3}`;
                }
                return match;
            });
        }
    }

    return lines.join('\n');
}

export function fixCssSelectorsInJs(jsCode: string): string {
  const regex = /(getElementById|getElementsByClassName|querySelector|querySelectorAll|closest)\((['"`])(.*?)\2\)/gs;

  const replaceSelector = (match: string, method: string, quote: string, selector: string) => {
      let fixedSelector = selector;

      if (method === "getElementById" || method === "getElementsByClassName") {
          fixedSelector = selector.replace(/^(\d[\w-]*)/, '_$1');
      } else if (method === "querySelector" || method === "querySelectorAll" || method === "closest") {
          fixedSelector = selector.replace(/([.#])(\d[\w-]*)/g, '$1_$2');
      }

      return `${method}(${quote}${fixedSelector}${quote})`;
  };

  return jsCode.replace(regex, replaceSelector);
}

export const insertStyles = (existingCSS: string, CompletionCSS: string) => {

  // console.log("existingCSS: ", existingCSS, "CompletionCSS: ", CompletionCSS);

  if (existingCSS === "") return CompletionCSS;

  const parser = new cssjs();

  const parsedOutput: any = parser.parseCSS(existingCSS);
  const parsedCompletion: any = parser.parseCSS(CompletionCSS);
  const parsedJoined: any = parsedOutput.concat(parsedCompletion);

  const transformedStyles: any = {};

  parsedJoined.forEach((style: any) => {
    const selector = style.selector;
    // const selector = normalizeSelector(style.selector);
    const type = style.type;

    if (type === undefined) {
      const rules = style.rules;

      if (!transformedStyles[selector]) {
        transformedStyles[selector] = { rules: [] };
      }

      rules.forEach((rule: any) => {
        const existingRuleIndex = transformedStyles[selector].rules.findIndex(
          (existingRule: any) => existingRule.directive === rule.directive
        );

        if (existingRuleIndex !== -1) {
          transformedStyles[selector].rules[existingRuleIndex] = rule;
        } else {
          transformedStyles[selector].rules.push(rule);
        }
      });
    } else if (type === 'media') {
      const subStyles = style.subStyles;

      if (!transformedStyles[selector]) {
        transformedStyles[selector] = { type: 'media', subStyles: [] };
      }

      subStyles.forEach((s: any) => {
        const subSelector = s.selector;
        const subRules = s.rules;

        const existingSubStyleIndex = transformedStyles[selector].subStyles.findIndex(
          (existingSubStyle: any) => existingSubStyle.selector === subSelector
        );

        if (existingSubStyleIndex !== -1) {
          transformedStyles[selector].subStyles[existingSubStyleIndex].rules = subRules;
        } else {
          transformedStyles[selector].subStyles.push({ selector: subSelector, rules: subRules });
        }
      });
    }

  });

  const outputArray = Object.keys(transformedStyles).map((selector: any) => {
    if (selector.startsWith('@media')) {
      return {
        selector: selector,
        type: 'media',
        subStyles: transformedStyles[selector].subStyles,
      };
    } else {
      return {
        selector: selector,
        rules: transformedStyles[selector].rules,
      };
    }
  });

  const uniqueKeyframes = parsedJoined
    .filter((s: any) => s.type === 'keyframes')
    .reduce((uniqueKeyframesArray: any[], currentKeyframe: any) => {
      const isDuplicate = uniqueKeyframesArray.some((existingKeyframe) => existingKeyframe.styles === currentKeyframe.styles);

      if (!isDuplicate) {
        uniqueKeyframesArray.push(currentKeyframe);
      }

      return uniqueKeyframesArray;
    }, []);

    console.log("so this is running ##########################", writeCSSFromRules([...outputArray, ...uniqueKeyframes]));
  return writeCSSFromRules([...outputArray, ...uniqueKeyframes]);
}

// export const processTailwindClass = (className: string): { value: string, property: string | null } => {
//   let value: string;
//   let property: string | null;

//   let arr: string[];
//   if (className[0] === '-') {
//     arr = className.split('-');
//     arr[1] = `-${arr[1]}`;
//     arr = arr.slice(1);
//   } else {
//     arr = className.split('-');
//   }

//   if (arr.length === 1) {
//       value = arr[0];
//       property = null;
//   } else {
//       const lastElement = arr[arr.length - 1];
//       const parsedLastElement = parseInt(lastElement);
      
//       if (!isNaN(parsedLastElement)) {

//           if (arr.length === 2) {
//             value = `${arr[arr.length - 1]}`;
//             arr.pop();
//           } else {
//             value = `${arr[arr.length - 2]}-${lastElement}`;
//             arr.pop();
//             arr.pop();
//           }
//       } else {
//           value = lastElement;
//           arr.pop();
//       }
      
//       property = arr.join("-");
//   }

//   return { property, value };
// }