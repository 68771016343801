import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState, useResetRecoilState, useRecoilValue } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { ProjectCommonElements, IsUploadingSpinner, IsDeployingSpinner, IsTranscribingSpinner, IsGeneratingImageSpinner, IsGeneratingLoadingScreen, IsGeneratingSpinner, SelectedProjectMode, projectsState, DefaultProjectSourceFile, selectedProjectSelector, pagesState, selectedPageSelector, ViewportSourceDocument, ChatBubbles, ContentUndoHistory, ConversationHistoryUndoHistory, UndoHistoryStepIndex, HTMLCode, CSSCode, JavaScriptCode, LocalContext, ConversationHistory, currentUserState, ViewportTree, PageLinks, PageImages, OutlinerTree, LocalContextCSS, PageForms, GlobalContextOuterHTML, ProjectForms, MessageGroupTypes, CurrentMessageGroupType, IsGlowingTranslate } from '../state';
import { Project, Page } from '../types';
import { useApiActions } from './api-actions';
import { usePageActions } from './';
import { constructPageLinksArray, constructPageFormsArray, constructPageImagesArray, hasBackgroundImage } from '../components/engine/links-tools';
import { useSIFOActions, useOpenaiActions } from './';
import AWS from 'aws-sdk';
import { useToast } from '@chakra-ui/react';
import { extractColorPalette, overwriteColors } from '../components/engine/color-palette/stylesheets-utils';
import { createThemeColorMapping } from '../components/engine/color-palette/mapping';

export const useProjectActions = () => {

  // I need currentUser here...

  const setUser = useSetRecoilState(currentUserState);
  const toast = useToast();

  const setPageLinks = useSetRecoilState(PageLinks);
  const setPageForms = useSetRecoilState(PageForms);
  const setProjectForms = useSetRecoilState(ProjectForms);
  const setPageImages = useSetRecoilState(PageImages);
  const [isGlowingTranslate, setIsGlowingTranslate] = useRecoilState<any>(IsGlowingTranslate);
  const resetPages = useResetRecoilState(pagesState);

  const navigate = useNavigate();
  const [projects, setProjects] = useRecoilState(projectsState);
  const [selectedProject, setSelectedProject] = useRecoilState(selectedProjectSelector);
  const { executeApiAction } = useApiActions();
  const { updatePageContent, createPage, fetchPageContent, fetchConversationHistory, fetchChat, fetchLinks, fetchForms, fetchImages } = usePageActions();
  const setPages = useSetRecoilState(pagesState);
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageSelector);
  const user = useRecoilValue(currentUserState);
  const setViewportSourceDocument = useSetRecoilState(ViewportSourceDocument);
  const resetChatBubbles = useResetRecoilState(ChatBubbles);

  const setConversationHistory = useSetRecoilState(ConversationHistory);
  const setContentUndoHistory = useSetRecoilState(ContentUndoHistory);
  const setConversationHistoryUndoHistory = useSetRecoilState(ConversationHistoryUndoHistory);

  const resetUndoHistoryStepIndex = useResetRecoilState(UndoHistoryStepIndex);
  const resetLocalContext = useResetRecoilState(LocalContext);
  const resetLocalContextCSS = useResetRecoilState(LocalContextCSS);
  const resetViewportTree = useResetRecoilState(ViewportTree);
  const resetGlobalContextOuterHTML = useResetRecoilState(GlobalContextOuterHTML);
  const resetOutlinerTree = useResetRecoilState(OutlinerTree);
  const resetPageLinks = useResetRecoilState(PageLinks);
  const resetPageForms = useResetRecoilState(PageForms);
  const resetPageImages = useResetRecoilState(PageImages);

  const setIsGeneratingLoadingScreen = useSetRecoilState(IsGeneratingLoadingScreen);
  const setIsGeneratingSpinner = useSetRecoilState(IsGeneratingSpinner);
  const setCurrentMessageGroupType = useSetRecoilState(CurrentMessageGroupType);

  const resetIsGeneratingSpinner = useResetRecoilState(IsGeneratingSpinner);
  const resetIsGeneratingLoadingScreen = useResetRecoilState(IsGeneratingLoadingScreen);
  const resetIsGeneratingImageSpinner = useResetRecoilState(IsGeneratingImageSpinner);
  const resetIsTranscribingSpinner = useResetRecoilState(IsTranscribingSpinner);
  const resetIsDeployingSpinner = useResetRecoilState(IsDeployingSpinner);
  const resetIsUploadingSpinner = useResetRecoilState(IsUploadingSpinner);

  const resetHTMLCode = useResetRecoilState(HTMLCode);
  const resetCSSCode = useResetRecoilState(CSSCode);
  const resetJavaScriptCode = useResetRecoilState(JavaScriptCode);

  const setProjectCommonElements = useSetRecoilState(ProjectCommonElements);
  const setSelectedProjectMode = useSetRecoilState(SelectedProjectMode);
  const setDefaultProjectSourceFile = useSetRecoilState(DefaultProjectSourceFile);

  const setHTMLCode = useSetRecoilState(HTMLCode);
  const setCSSCode = useSetRecoilState(CSSCode);
  const setJavaScriptCode = useSetRecoilState(JavaScriptCode);

  const { csvToObject, buildLocalizationMap, cleanTranslation } = useSIFOActions();
  const { getTemplateAutomation } = useOpenaiActions();

  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:98689dd4-f972-4f76-8aec-0b54f0cb2c08'
    }),
    httpOptions: {
      timeout: 600000 
    }
  });
  const lambda = new AWS.Lambda();

  let lambdaFunctionName : any

  if (window.location.hostname === 'app.sifo.ai') {
    lambdaFunctionName = 'my-app-api-production-poller'
  } else if (window.location.hostname === 'app.staging.sifo.ai') {
    lambdaFunctionName = 'my-app-api-staging-poller'
  }
  const handleAutomating = () => {
    setCurrentMessageGroupType(MessageGroupTypes.AUTOMATING);
    setIsGeneratingLoadingScreen(true);
  };
  const getProject = async (id: string) =>
    await executeApiAction<Project>({
      action: () => ApiClient.getProject(id),
      onError: () => navigate('/'),
      errorMessage: 'Failed to load project, refresh to try again',
    });
      const invokeLambdaAutomation = async (payload: any) => {
        const lambdaPayload = {
            FunctionName: lambdaFunctionName,
            InvocationType: 'RequestResponse',
            Payload: JSON.stringify(payload)
        };

        try {
            const lambdaResponse = await lambda.invoke(lambdaPayload).promise();
            const responsePayload = JSON.parse(lambdaResponse.Payload as any);

            if (responsePayload.statusCode === 200) {
                return JSON.parse(responsePayload.body);
            } else {
                throw new Error(responsePayload.body);
            }
        } catch (error) {
            console.error('Lambda invocation error:', error);
            throw error;
        }
    };
    const handleTemplateAutomation = async (pages: any, businessName: string, businessDescription: string): Promise<any[]> => {
      if (!businessName) throw new Error("No business name was supplied.");
      if (!businessDescription) throw new Error("No business description was supplied.");

      const parser = new DOMParser();

      try {
          const templateMap = await Promise.all(pages.map(async (page: Page) => {
              const pageDocument = parser.parseFromString(page.content, 'text/html');

              return {
                  pid: page.id,
                  map: buildLocalizationMap(pageDocument) // Re-use from translation feature.
              };
          })).then(results => results.filter(Boolean));

          // Replace the direct getTemplateAutomation call with Lambda invocation
          const response = await invokeLambdaAutomation({
              context: 'automation',
              templateMaps: templateMap,
              businessName,
              businessDescription,
              userId: user?.id  // If you need to track user ID
          });

          if (!response || !response.automation) {
              throw new Error("No automation in response");
          }

          const automationResults = await Promise.all(response.automation.map(async (automation: any) => {
              const correspondingPage = pages.find((page: Page) => page.id === automation.pid);
              if (!correspondingPage) {
                  throw new Error(`No corresponding page found for pid: ${automation.pid}`);
              }

              return await handleApplyAutomation(correspondingPage, automation.map);
          }));

          return automationResults;
      } catch (error) {
          console.error('Error in template automation:', error);
          throw error;
      }
  };

  // const handleTemplateAutomation = async (pages: any, businessName: string, businessDescription: string): Promise<any[]> => {
  //   if (!businessName) throw new Error("No business name was supplied.");
  //   if (!businessDescription) throw new Error("No business description was supplied.");

  //   const parser = new DOMParser();

  //   try {
  //     const templateMap = await Promise.all(pages.map(async (page: Page) => {
  //       const pageDocument = parser.parseFromString(page.content, 'text/html');

  //       return {
  //         pid: page.id,
  //         map: buildLocalizationMap(pageDocument) // Re-use from translation feature.
  //       };
  //     })).then(results => results.filter(Boolean));

  //     const automations: any = await getTemplateAutomation(templateMap, businessName, businessDescription);

  //     // console.log("$$$$$$$$$$$$$$$$ automations $$$$$$$$$$$$$$$$: ", automations);

  //     const automationResults = await Promise.all(automations.map(async (automation: any) => {
  //       const correspondingPage = pages.find((page: Page) => page.id === automation.pid);
  //       if (!correspondingPage) {
  //         throw new Error(`No corresponding page found for pid: ${automation.pid}`);
  //       }

  //       return await handleApplyAutomation(correspondingPage, automation.map);
  //     }));

  //     // console.log("automationResults: ", automationResults);

  //     return automationResults;
  //   } catch (error) {
  //     console.error('Error in template automation:', error);
  //     throw error;
  //   }
  // };

  const handleApplyAutomation = async (page: any, automation: any) => {
    if (!page) throw new Error("No page was supplied.");

    const parser = new DOMParser();

    const pageDocument = parser.parseFromString(page.content, 'text/html');
    if (!pageDocument) throw new Error("No parsed page document was obtained.");

    const parsedAutomation: any = csvToObject(cleanTranslation(automation) as string);

    // console.log("$$$$$$$$$$$$$$$$ parsedAutomation $$$$$$$$$$$$$$$$: ", parsedAutomation);

    // Insert in page,
    for (const key in parsedAutomation) {
      console.log("key: ", key);
      if (parsedAutomation.hasOwnProperty(key)) {
        const value = parsedAutomation[key];
        try {
          const element = pageDocument.querySelector(`#${key}`);

          if (element) {
            // element.textContent = value;
            element.innerHTML = value;
            element.innerHTML = element.innerHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
          } else {
            console.error(`Element with ID '${key}' not found.`);
          }
        } catch {
          throw new Error('An error occured during in-place insertion.')
        }
      }
    }

    page.content = pageDocument.documentElement.outerHTML;

    if (user?.plan_id === 'FREE_TRIAL') {
      console.log('HELLO', user?.trial_used);

      if (user && !user.trial_used) {
        setTimeout(() => {
          setIsGlowingTranslate(true);

          toast({
            title: "Let's make your site International.",
            description: 'Click on the glowing button in the right panel.',
            variant: 'solid',
            status: 'info',
            position: 'bottom',
            duration: 4000,
          });
        }, 4000);
      }

    }


    return page;
  }

  const createProject = async ({ name, selectedProjectMode, favicon, fromTemplate, templateAutomation, businessName, businessDescription, themeColor, onProjectCreation }: { name: string, selectedProjectMode: string, favicon: string, fromTemplate: boolean, templateAutomation: boolean, businessName: string, businessDescription: string, themeColor: any, onProjectCreation: boolean }) =>
    await executeApiAction({
      action: () => ApiClient.createProject({ name, selectedProjectMode, favicon, fromTemplate }),
      onSuccess: async ({ project, source_file }: any) => {

        resetPages();


        setProjectCommonElements(project.common_elements || []);

        setDefaultProjectSourceFile(source_file);

        setContentUndoHistory([
          {
            timestamp: new Date().toLocaleString(),
            label: 'Initial',
            content: source_file
          },
        ]);

        if (!fromTemplate) {
          // resetChatBubbles();

          setViewportSourceDocument(
            source_file
          );

          resetUndoHistoryStepIndex();
          resetLocalContext();
          resetLocalContextCSS();
          resetViewportTree();
          resetGlobalContextOuterHTML();
          resetOutlinerTree();
          resetPageLinks();
          resetPageForms();
          resetPageImages();

          resetHTMLCode();
          resetCSSCode();
          resetJavaScriptCode();

        }

        resetChatBubbles();


        setProjects([project, ...projects]);
        setSelectedProject(project);
        setSelectedProjectMode(project.mode);

        // Will probably construct half of messages on the front-end,
        // and the second half on the backend,
        // and once again, conversation history was used for context, it is no longer needed.

        if (fromTemplate) {
          if (onProjectCreation && templateAutomation) {
            fetchProjectPages({ project_id: project.id, fromTemplate, onProjectCreation, templateAutomation, businessName, businessDescription, themeColor });
          } else {
            fetchProjectPages({ project_id: project.id, fromTemplate, onProjectCreation, themeColor });
          }
        } else {
          createPage({
            projectId: project.id, title: 'Home', path: 'index.html', content: source_file,
            // projectId: project.id, title: 'Home', content: (projectModes as any)[project.mode].source,
            conversationHistory: [
              {
                role: 'system',
                content: ''
                // content: (projectModes as any)[project.mode].systemMessage
              },
            ],
            chat: [
              {
                date: Date.now(),
                type: 'message',
                source: 'assistant',
                content: "Hi! SIFO here. Let's create cool stuff together!"
              }
            ],
            links: [],
            images: [],
            forms: [],
            locale: 'Default',
            isIndex: true
          });
        }

        // This needs to not go lower than 0.
        // setUser((previousUser: any) => {
        //   return {
        //     ...previousUser,
        //     projects_allowed: previousUser.projects_allowed - 1
        //   }
        // })


        // RESETS
        if (!fromTemplate) {
          resetIsGeneratingSpinner();
          resetIsGeneratingLoadingScreen();
          resetIsGeneratingImageSpinner();
          resetIsTranscribingSpinner();
          resetIsDeployingSpinner();
          resetIsUploadingSpinner();
        }

        // Useup credits, get user back, set user
        const { me } = await ApiClient.getMe();
        setUser(me);

        navigate(`/projects/${project.id}/dashboard`);
      },
      errorMessage: 'Failed to create project',
      successMessage: 'Project created',
    });

  const updateProject = async (data: Partial<Project>) =>
    await executeApiAction({
      action: () => ApiClient.updateProject(data),
      onSuccess: (project: Project) => {
        if (project.id === selectedProject?.id) {
          setSelectedProject(project);
        }
        setProjects(projects.map((p) => (p.id === project.id ? project : p)));
      },
      errorMessage: 'Failed to update project',
      successMessage: 'Project saved',
    });

  const deleteProject = async (id: string) =>
    await executeApiAction({
      action: () => ApiClient.deleteProject(id),
      onSuccess: () => {
        setProjects(projects.filter((p) => p.id !== id));
        if (selectedProject?.id === id) {
          setSelectedProject(null);
        }

        navigate('/');
      },
      errorMessage: 'Failed to delete project',
      successMessage: 'Project deleted',
    });

  const parseWithIframe = (html: string): Promise<Document> => {
    return new Promise((resolve, reject) => {
      const iframe = document.createElement('iframe');
      iframe.style.cssText = `
          position: absolute;
          width: 1024px;
          height: 800px;
          display: none;
          pointer-events: none;
        `;
      document.body.appendChild(iframe);

      const cleanup = () => document.body.removeChild(iframe);

      const handleLoad = () => {
        const doc = iframe.contentDocument || iframe.contentWindow?.document;
        if (!doc) {
          cleanup();
          reject(new Error('Could not get iframe document'));
          return;
        }

        const elementsWithoutId = doc.querySelectorAll('div#body *:not([id])');

        elementsWithoutId.forEach((element: any) => {
          const tag = element.tagName.toLowerCase() || 'unknown';
          const randomId = Math.floor(Math.random() * 1000000);
          element.setAttribute("id", `${tag}-${randomId}`);
        })

        resolve(doc);
      };

      iframe.addEventListener('load', handleLoad);
      iframe.srcdoc = html;
    });
  };


  const refreshPageWithForms = async (pageId: string) =>
    await executeApiAction({
      action: () => Promise.all([
        ApiClient.fetchPageContent(pageId),
        ApiClient.fetchForms(pageId)
      ]),
      onSuccess: async ([content, forms]: [string, any[]]) => {
        const parser = new DOMParser();
        const viewport = await parseWithIframe(content);

        // Process forms similar to fetchProjectPages
        const pageFormTags: NodeListOf<Element> = viewport.querySelectorAll('form');
        let pageForms: any[] = [];

        if (pageFormTags.length > 0 && user) {
          const viewportForms: any[] = constructPageFormsArray(pageFormTags, pageId, user.email);
          // Merge viewport forms with DB forms
          pageForms = viewportForms.map(viewportForm => {
            const dbForm: any = forms.find((df: any) => df.dom_id === viewportForm.dom_id);
            return dbForm ? { ...viewportForm, ...dbForm } : viewportForm;
          });
        }

        // Update the page in pages state with new content and forms
        setPages((prevPages) =>
          prevPages.map((page) =>
            page.id === pageId
              ? {
                ...page,
                content: viewport.documentElement.outerHTML,
                forms: pageForms
              }
              : page
          )
        );

        // If this is the current page, update HTML code
        const pageDocument = parser.parseFromString(content, 'text/html');
        const bodyElement = pageDocument.querySelector('div#body');
        if (bodyElement) setHTMLCode(bodyElement.innerHTML);
      },
      errorMessage: 'Failed to refresh page content and forms.',
      successMessage: 'Page refreshed successfully.',
    });

  // const updateProjectPages = async (project_id: string, pages: Page[]) =>
  const updateProjectPages = async (pages: any) =>
    await executeApiAction({
      // action: () => ApiClient.updateProjectPages(project_id, pages),
      action: () => ApiClient.updateProjectPages(pages),
      errorMessage: 'Failed to update project pages.',
      successMessage: 'Project pages updated.',
    });

  const updateProjectCommonElements = async (project_id: string, common_elements: any[]) =>
    await executeApiAction({
      action: () => ApiClient.updateProjectCommonElements(project_id, common_elements),
      errorMessage: 'Failed to update project common elements.',
      successMessage: 'Project common elements updated.',
    });

  const fetchProjectPages = async ({ project_id, fromTemplate, onProjectCreation, templateAutomation, businessName, businessDescription, themeColor }: { project_id: string, fromTemplate?: boolean, onProjectCreation?: boolean, templateAutomation?: boolean, businessName?: string, businessDescription?: string, themeColor?: any }) =>
    await executeApiAction({
      action: () => Promise.all([
        ApiClient.fetchProjectPages(project_id),
        ApiClient.getProjectForms(project_id) // Fetch all forms at once
      ]),
      onSuccess: async ([pages, allForms]: [Page[], any[]]) => {
        // Organize forms by page_id
        const formsByPage: any = new Map();
        allForms.forEach(form => {
          if (!formsByPage.has(form.page_id)) {
            formsByPage.set(form.page_id, []);
          }
          formsByPage.get(form.page_id).push({
            ...form,
            is_active: Boolean(form.is_active)
          });
        });

        setProjectForms(formsByPage);



        const htmlParser = new DOMParser();

        let modifiedPages: Page[] = pages;
        let timer;

        if (fromTemplate) {

          if (templateAutomation) {
            if (!businessName) throw new Error("No business name was supplied.");
            if (!businessDescription) throw new Error("No business description name was supplied.");

            setIsGeneratingSpinner(true);
            // setIsGeneratingLoadingScreen(true);
            timer = setTimeout(() => {
              // setIsGeneratingLoadingScreen(true);
              handleAutomating();
            }, 2000);
            const automatedPages = await handleTemplateAutomation(modifiedPages, businessName, businessDescription);
            modifiedPages = automatedPages;

            console.log("automatedPages: ", automatedPages);
          }

          //   modifiedPages = await Promise.all(modifiedPages.map(async (page: any) => {
          //     const viewport = await parseWithIframe(page.content);

          //     const pageFormTags: any = viewport.querySelectorAll('form');

          //     if (pageFormTags.length > 0 && selectedPage && user) {
          //       const pageForms: any = constructPageFormsArray(pageFormTags, selectedPage.id, user.email);
          //       page.forms = pageForms;
          //     }

          //     const pageAmchorTags: any = viewport.querySelectorAll('a');
          //     if (pageAmchorTags.length > 0) {
          //       const pageLinks: any = constructPageLinksArray(pageAmchorTags);
          //       page.links = pageLinks;
          //     }

          //     const allElements: any = viewport.querySelectorAll('*');

          //     const imageElements: any = Array.from(allElements).filter((element: any) => {
          //       if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
          //         return true;
          //       }
          //     });

          //     if (imageElements.length > 0) {
          //       const pageImages: any = constructPageImagesArray(imageElements);
          //       page.images = pageImages;
          //     }

          //     page.content = viewport.documentElement.outerHTML;
          //     return page;
          //   }));


        }

        modifiedPages = await Promise.all(modifiedPages.map(async (page: any) => {
          const viewport = await parseWithIframe(page.content);
          const pageFormTags: any = viewport.querySelectorAll('form');

          if (pageFormTags.length > 0 && user) {
            const viewportForms = constructPageFormsArray(pageFormTags, page.id, user.email);
            const dbForms = formsByPage.get(page.id) || [];

            // Merge viewport forms with DB forms
            page.forms = viewportForms.map(viewportForm => {
              const dbForm = dbForms.find((df: any) => df.dom_id === viewportForm.dom_id);
              return dbForm ? { ...viewportForm, ...dbForm } : viewportForm;
            });
          }

          if (fromTemplate) {
            const pageAmchorTags: any = viewport.querySelectorAll('a');
            if (pageAmchorTags.length > 0) {
              const pageLinks: any = constructPageLinksArray(pageAmchorTags);
              page.links = pageLinks;
            }

            const allElements: any = viewport.querySelectorAll('*');

            const imageElements: any = Array.from(allElements).filter((element: any) => {
              if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
                return true;
              }
            });

            if (imageElements.length > 0) {
              const pageImages: any = constructPageImagesArray(imageElements);
              page.images = pageImages;
            }
          }

         // page.content = viewport.documentElement.outerHTML;
          return page;
        }));


        // Theme color code goes here

        // console.log("themeColor: ", themeColor);
        // console.log("fromTemplate: ", fromTemplate);
        // console.log("onProjectCreation: ", onProjectCreation);

        // try {
          if (themeColor && fromTemplate && onProjectCreation) {
            modifiedPages = await Promise.all(modifiedPages.map(async (page: any) => {
              const viewport = await parseWithIframe(page.content);
              
              const { originalToNormalized, normalizedColors } = extractColorPalette(viewport);
              const colorMapping = createThemeColorMapping(themeColor, normalizedColors);
              
              const cleanViewport = htmlParser.parseFromString(page.content, "text/html");
              overwriteColors(colorMapping, viewport, cleanViewport, originalToNormalized);
  
              page.content = cleanViewport.documentElement.outerHTML;
              return page;
            }));
          }
        // } catch (error){
        //   console.log(error);
        // }


        // I still need to fix the bug with it not being present immediately for some reason,
        // maybe using the regular parser instead of the iframe parser would do the trick.
        // or, a setviewportsourcedocument is messing up things somewhere.

        const pagesCopy = [...modifiedPages];
        pagesCopy.sort((a: any, b: any) => b.is_index - a.is_index);

        // This wasn't even the solutionm, just keeping it here in case.
        // if (fromTemplate && onProjectCreation && (themeColor || templateAutomation)) {

        // Loop through pages, and update page content with page id
        // if (fromTemplate && onProjectCreation && templateAutomation) {
        if (fromTemplate && onProjectCreation) {

          if (templateAutomation || themeColor) {
            updateProjectPages(pagesCopy);

            // const updateDatabasePages = pagesCopy.map((page: any) => {
            //   return {
            //     id: page.id,
            //     content: page.content
            //   }
            // }) 
        
            // updateProjectPages(updateDatabasePages);
          }

          // Promise.all(pagesCopy.map(async (page: any) => {
          //   await updatePageContent(page.id, page.content);
          // }))


          setIsGeneratingSpinner(false);
          setIsGeneratingLoadingScreen(false);
          clearTimeout(timer);
        }

        setPages(pagesCopy);

        console.log("pagesCopy[0]: ", pagesCopy[0]);
        setSelectedPage(pagesCopy[0]);


        // resetChatBubbles();

        setContentUndoHistory([
          {
            timestamp: new Date().toLocaleString(),
            label: 'Initial',
            // content: ''
            // content: pagesCopy[0].content
            content: ` ${pagesCopy[0].content} `
          },
        ]);

        // console.log("pagesCopy[0].content: ********************", pagesCopy[0].content);

        setConversationHistoryUndoHistory([
          pagesCopy[0].conversation_history
        ])

        resetUndoHistoryStepIndex();
        resetLocalContext();
        resetLocalContextCSS();
        resetViewportTree();
        resetGlobalContextOuterHTML();
        resetOutlinerTree();
        resetPageLinks();
        resetPageForms();
        resetPageImages();

        // const parser = new DOMParser();
        let pageDocument = htmlParser.parseFromString(pagesCopy[0].content, 'text/html');

        const bodyElement = pageDocument.querySelector('div#body');
        const styleElement = pageDocument.querySelector('style[data-dev-tag="inject-into"]');
        const scriptElement = pageDocument.querySelector('script[data-dev-tag="inject-into"]');

        if (bodyElement) setHTMLCode(bodyElement.innerHTML);
        if (styleElement) setCSSCode(String(styleElement.textContent));
        if (scriptElement) setJavaScriptCode(scriptElement.innerHTML);


        // if (!onProjectCreation) {

        // if (fromTemplate && onProjectCreation && templateAutomation) {
        //   setViewportSourceDocument(pagesCopy[0].content)
        // } else {
        //   fetchPageContent(pagesCopy[0].id);
        // }

        // This should work on its own, so why did I have complicated stuff like above.
        setViewportSourceDocument(pagesCopy[0].content)


        fetchConversationHistory(pagesCopy[0].id);
        fetchChat(pagesCopy[0].id);
        // }

        // Check locally first
        // Or check fromTemplate and decide with it
        if (pagesCopy[0].links === 0) {
          fetchLinks(pagesCopy[0].id);
        } else {
          setPageLinks(pagesCopy[0].links);
        }

        setPageForms(pagesCopy[0].forms);


        if (pagesCopy[0].images === 0) {
          fetchImages(pagesCopy[0].id);
        } else {
          setPageImages(pagesCopy[0].images);
        }

        resetIsGeneratingSpinner();
        resetIsGeneratingLoadingScreen();
        resetIsGeneratingImageSpinner();
        resetIsTranscribingSpinner();
        resetIsDeployingSpinner();
        resetIsUploadingSpinner();
      },
      errorMessage: 'Failed to fetch project pages.',
      successMessage: 'Project pages fetched.',
    });

  return {
    getProject: useCallback(getProject, [executeApiAction, navigate]),
    createProject: useCallback(createProject, [setProjects, setSelectedProject, executeApiAction, navigate, projects, createPage, resetCSSCode, resetChatBubbles, resetHTMLCode, resetJavaScriptCode, resetLocalContext, resetUndoHistoryStepIndex, setViewportSourceDocument, setContentUndoHistory, setConversationHistory, setConversationHistoryUndoHistory, setSelectedProjectMode]),
    updateProject: useCallback(updateProject, [
      setProjects,
      setSelectedProject,
      selectedProject,
      executeApiAction,
      projects,
    ]),
    deleteProject: useCallback(deleteProject, [
      setProjects,
      setSelectedProject,
      selectedProject,
      executeApiAction,
      projects,
      navigate,
    ]),
    refreshPageWithForms: useCallback(refreshPageWithForms, [executeApiAction, setPages, setHTMLCode, user, parseWithIframe, constructPageFormsArray]),
    fetchProjectPages: useCallback(fetchProjectPages, [executeApiAction, fetchConversationHistory, fetchChat, fetchPageContent, resetChatBubbles, resetLocalContext, resetUndoHistoryStepIndex, setCSSCode, setContentUndoHistory, setConversationHistoryUndoHistory, setHTMLCode, setJavaScriptCode, setPages, setSelectedPage]),
    updateProjectPages: useCallback(updateProjectPages, [executeApiAction]),
    parseWithIframe: useCallback(parseWithIframe, [executeApiAction]),
    updateProjectCommonElements: useCallback(updateProjectCommonElements, [executeApiAction]),
    // updateProjectPagesContents: useCallback(updateProjectPagesContents, [executeApiAction])
  };
};