export const projectBootstrapTemplates = [
  {
    display_name: 'Wallet',
    name: 'wallet',
    thumbnail: 'modes/preview_templates/wallet.webp',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Saasy',
    name: 'saasy',
    thumbnail: 'modes/preview_templates/saasy.png',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Pixa Ai',
    name: 'pixa',
    thumbnail: 'modes/preview_templates/pixa.png',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Dtox',
    name: 'dtox',
    thumbnail: 'modes/preview_templates/dtox.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Promodise',
    name: 'promodise',
    thumbnail: 'modes/preview_templates/promodise.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'SaaSy Dark',
    name: 'saasydark',
    thumbnail: 'modes/preview_templates/saasydark.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Landwind',
    name: 'landwind',
    thumbnail: 'modes/preview_templates/landwind.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Small-Apps',
    name: 'smallapps',
    thumbnail: 'modes/preview_templates/smallapps.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'GymFit',
    name: 'gymfit',
    thumbnail: 'modes/preview_templates/gymfit.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Saas Ai',
    name: 'saasai',
    thumbnail: 'modes/preview_templates/saasai.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Constra',
    name: 'constra',
    thumbnail: 'modes/preview_templates/constra.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Novena',
    name: 'novena',
    thumbnail: 'modes/preview_templates/novena.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Thomson',
    name: 'thomson',
    thumbnail: 'modes/preview_templates/thomson.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Rappo',
    name: 'rappo',
    thumbnail: 'modes/preview_templates/rappo.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Orbitor',
    name: 'orbitor',
    thumbnail: 'modes/preview_templates/orbitor.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Navigator App',
    name: 'navigatorapp',
    thumbnail: 'modes/preview_templates/navigatorapp.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Newsbit',
    name: 'newsbit',
    thumbnail: 'modes/preview_templates/newsbit.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Navigator',
    name: 'navigator',
    thumbnail: 'modes/preview_templates/navigator.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Meghna',
    name: 'meghna',
    thumbnail: 'modes/preview_templates/meghna.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Medic',
    name: 'medic',
    thumbnail: 'modes/preview_templates/medic.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Kross',
    name: 'kross',
    thumbnail: 'modes/preview_templates/kross.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'GoDocs',
    name: 'godocs',
    thumbnail: 'modes/preview_templates/godocs.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Reporter',
    name: 'reporter',
    thumbnail: 'modes/preview_templates/reporter.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Influencer',
    name: 'influencer',
    thumbnail: 'modes/preview_templates/influencer.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Logbook',
    name: 'logbook',
    thumbnail: 'modes/preview_templates/logbook.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Agen',
    name: 'agen',
    thumbnail: 'modes/preview_templates/agen.webp',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Vex',
    name: 'vex',
    thumbnail: 'modes/preview_templates/vex.webp',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Classimax',
    name: 'classimax',
    thumbnail: 'modes/preview_templates/classimax.webp',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Megakit',
    name: 'megakit',
    thumbnail: 'modes/preview_templates/megakit.webp',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Educenter',
    name: 'educenter',
    thumbnail: 'modes/preview_templates/educenter.webp',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Infinity',
    name: 'infinity',
    thumbnail: 'modes/preview_templates/infinity.webp',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
];

export const projectBootstrapTemplatesAsLowerCaseStrings = projectBootstrapTemplates.map(template => template.name.toLowerCase());