import { useCallback } from 'react';
import { ApiClient } from '../lib/clients/api-client';
import { useApiActions } from './api-actions';

export const useOpenaiActions = () => {
  const { executeApiAction } = useApiActions();

  const getTranscription = async (formData: any) =>
    await executeApiAction({
      action: () => ApiClient.transcribe(formData),
      errorMessage: 'Failed to transcribe audio.',
      successMessage: 'Transcription successful.',
    });

  const fetchUnsplash = async (searchTerm: string, page: number | null) =>
    await executeApiAction({
      action: () => ApiClient.fetchUnsplash(searchTerm, page),
      errorMessage: 'Failed to fetch images from Unsplash.',
      successMessage: 'Images fetched successfully.',
    });
  const unsplashTrack = async (link: string) =>
    await executeApiAction({
      action: () => ApiClient.unsplashTrack(link),
      errorMessage: 'Failed to track images from Unsplash.',
      successMessage: 'Images tracked successfully.',
    });


  // It could take a file, as in formdata, or simply a url, 
  // a from parameter, from url or from formdata
  // do it like gettranscription end point
  const uploadToS3 = async (formData: any) =>
    await executeApiAction({
      action: () => ApiClient.uploadToS3(formData),
      errorMessage: 'Failed to upload to S3 .',
      successMessage: 'Upload to S3 successful.',
    });

  const triggerFunctionCall = async (prompt: string, options?: any) =>
    await executeApiAction({
      action: () => ApiClient.triggerFunctionCall(prompt),
      errorMessage: 'Failed to trigger AI action.',
      successMessage: 'AI action triggered successfully.',
    });

  const getImageGeneration = async (prompt: string, options?: any) =>
    await executeApiAction({
      action: () => ApiClient.getImageGeneration(prompt, options),
      errorMessage: 'Failed to generate image.',
      successMessage: 'Image generation successful.',
    });

  const getCompletions = async (messages: any, options?: any) =>
    await executeApiAction({
      action: () => ApiClient.getCompletions(messages, options),
      errorMessage: 'Failed to generate.',
      successMessage: 'Generation successful.',
    });

  const getColorPalette = async (originalPalette: any, themeColor: any) =>
    await executeApiAction({
      action: () => ApiClient.getColorPalette(originalPalette, themeColor),
      errorMessage: 'Failed to get color palette.',
      successMessage: 'Color palette fetching successful.',
    });

  const getPageTranslation = async (localizationMap: any, language: string) =>
    await executeApiAction({
      action: () => ApiClient.getPageTranslation(localizationMap, language),
      errorMessage: 'Failed to translate page.',
      successMessage: 'Page translation successful.',
    });

  const getProjectTranslation = async (localizationMaps: any, language: string) =>
    await executeApiAction({
      action: () => ApiClient.getProjectTranslation(localizationMaps, language),
      errorMessage: 'Failed to translate project.',
      successMessage: 'Project translation successful.',
    });

  const getTemplateAutomation = async (templateMaps: any, businessName: string, businessDescription: string) =>
    await executeApiAction({
      action: () => ApiClient.getTemplateAutomation(templateMaps, businessName, businessDescription),
      errorMessage: 'Failed to automate project.',
      successMessage: 'Project automation successful.',
    });

  const getEnhancedCSS = async (elementHTML: string, elementCSS: string, options?: any) =>
    await executeApiAction({
      action: () => ApiClient.enhanceCSS(elementHTML, elementCSS, options),
      errorMessage: 'Failed to enhance styling.',
      successMessage: 'Styling enhancement successful.',
    });

  return {
    getTranscription: useCallback(getTranscription, [executeApiAction]),
    getImageGeneration: useCallback(getImageGeneration, [executeApiAction]),
    getCompletions: useCallback(getCompletions, [executeApiAction]),
    getColorPalette: useCallback(getColorPalette, [executeApiAction]),
    getPageTranslation: useCallback(getPageTranslation, [executeApiAction]),
    getProjectTranslation: useCallback(getProjectTranslation, [executeApiAction]),
    getTemplateAutomation: useCallback(getTemplateAutomation, [executeApiAction]),
    triggerFunctionCall: useCallback(triggerFunctionCall, [executeApiAction]),
    getEnhancedCSS: useCallback(getEnhancedCSS, [executeApiAction]),
    uploadToS3: useCallback(uploadToS3, [executeApiAction]),
    fetchUnsplash: useCallback(fetchUnsplash, [executeApiAction]),
    unsplashTrack: useCallback(unsplashTrack, [executeApiAction]),
  };
};