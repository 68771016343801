import React, { useState } from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

const ColorSwatchPicker = ({ onColorSelect }: { onColorSelect: any }) => {
    const [selectedColor, setSelectedColor] = useState(null);

    // const colors = [
    //     null,
    //     '#FF0000',
    //     '#00FF00',
    //     '#0000FF',
    //     '#FF00FF',
    //     '#FFFF00',
    //     '#FFC0CB',
    // ];

    const colors = [
        null,
        // Soft/Muted Shades
        '#E57373',
        '#81C784',
        '#64B5F6',
        '#BA68C8',
        '#FFD54F',
        '#F06292',
        '#4DB6AC',
        '#9575CD',
        '#FF8A65',
        '#AED581',
        '#7986CB',
        '#F48FB1',
        '#4DB6AC',
        '#FFB74D',
        '#7986CB',

        // Vibrant Shades
        '#FF1744',   // Vibrant Red
        '#00E676',   // Bright Green
        '#2979FF',   // Vivid Blue
        '#D500F9',   // Intense Magenta
        '#FFEA00',   // Bright Yellow
        '#FF4081',   // Hot Pink
        '#00BCD4',   // Bright Cyan
        '#7C4DFF',   // Deep Purple
        '#FF9100',   // Bright Orange
        '#00C853',   // Vivid Green
        '#3D5AFE',   // Intense Blue
        '#F50057',   // Radical Pink
        '#00B0FF',   // Bright Azure
        '#FF6D00',   // Deep Orange
        '#1DE9B6'    // Bright Teal
    ];

    const handleColorSelect = (color: any) => {
        setSelectedColor(color);
        onColorSelect(color);
    };

    const gradientEndColor = useColorModeValue('white', '#cfcfcf');

    return (
        <Flex flexWrap="wrap" gap={2}>
            {colors.map((color, index) => (
                <Box
                    key={index}
                    w={10}
                    h={10}
                    borderRadius="full"
                    cursor="pointer"
                    backgroundImage={`linear-gradient(135deg, ${color ? color : "transparent"}, ${gradientEndColor})`}
                    border={selectedColor === color ? '2px solid black' : 'none'}
                    onClick={() => handleColorSelect(color)}
                />
            ))}

            {/* <Text fontSize="sm" fontWeight="medium">
                {selectedColor}
            </Text> */}
        </Flex>
    );
};

export default ColorSwatchPicker;