import { atom } from 'recoil';

export const OutlinerDeleteElementId = atom({
    key: 'outliner-delete-element-id',
    default: null
});

export const ApplyThemeColor = atom({
    key: 'apply-theme-color',
    default: null
});

export const WipeIframeContent = atom({
    key: 'wipe-iframe-content',
    default: null
});

export const CreatePageFromLink = atom({
    key: 'create-page-from-link',
    default: null
});

export const GoToPageFromLink = atom({
    key: 'go-to-page-from-link',
    default: null
});

// If !null, it means it's triggered, and its value is set to the type of the fix.
export const Autofixer = atom({
    key: 'autofixer-trigger-type',
    default: null
});

export const AutofixerContext = atom({
    key: 'autofixer-context',
    default: null
});

export const IsGlowingEnhance = atom({
    key: 'is-glowing-enhance',
    default: null
});
export const IsGlowingTranslate = atom({
    key: 'is-glowing-translate',
    default: null
});


export const WillRepairJavascript = atom({
    key: 'will-repair-javascript',
    default: false
});

// export const IsCreatedPageFromLink = atom({
//     key: 'is-created-page-from-link',
//     default: null
// });