// Convert HEX to HSL
function hexToHSL(hex: string): [number, number, number] {
    let r = parseInt(hex.substr(1, 2), 16) / 255;
    let g = parseInt(hex.substr(3, 2), 16) / 255;
    let b = parseInt(hex.substr(5, 2), 16) / 255;
    
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let h = 0, s = 0, l = (max + min) / 2;
    
    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return [h * 360, s * 100, l * 100];
}

// Convert HSL back to HEX
function hslToHex(h: number, s: number, l: number): string {
    s /= 100;
    l /= 100;
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = l - c / 2;
    let r = 0, g = 0, b = 0;

    if (h >= 0 && h < 60) {
        r = c; g = x; b = 0;
    } else if (h >= 60 && h < 120) {
        r = x; g = c; b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0; g = c; b = x;
    } else if (h >= 180 && h < 240) {
        r = 0; g = x; b = c;
    } else if (h >= 240 && h < 300) {
        r = x; g = 0; b = c;
    } else {
        r = c; g = 0; b = x;
    }

    return `#${((1 << 24) + ((r + m) * 255 << 16) + ((g + m) * 255 << 8) + ((b + m) * 255) | 0).toString(16).slice(1).toUpperCase()}`;
}

// Function to map all colors to the theme color
export function createThemeColorMapping(themeColor: string, colors: string[]): any {
    const [themeHue, themeSaturation, themeLightness] = hexToHSL(themeColor);
    const colorMapping = new Map<string, string>();

    colors.forEach((color) => {
        const [hue, saturation, lightness] = hexToHSL(color);
        
        // Adjust the hue to match the theme color
        const newHue = themeHue; // Match the hue of the theme color
        
        // Adjust saturation and lightness slightly to make the new theme more cohesive
        const newSaturation = Math.min(saturation * 1.1, 100); // Increase saturation slightly
        const newLightness = Math.min(lightness * 1.1, 100); // Increase lightness slightly

        const newColor = hslToHex(newHue, newSaturation, newLightness);
        colorMapping.set(color, newColor);
    });

    const colorMappingObject = Object.fromEntries(colorMapping);

    return colorMappingObject;
}

// // Example Usage:
// const colors = ['#FF5733', '#33FF57', '#3357FF', '#F2F2F2'];  // Example colors
// const themeColor = '#FF0000';  // Red as theme color

// const colorMapping = createThemeColorMapping(themeColor, colors);
// console.log(colorMapping);
