import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SimpleGrid, Switch, Radio, RadioGroup, IconButton, Stack, FormLabel, Heading, Flex, Input, Text, Button, Spacer, Image, Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { FaChevronLeft, FaSave } from "react-icons/fa";
import { MdDelete, MdSettings } from 'react-icons/md';

import { AuthenticatedLayout } from '../layouts';
import { Project } from '../types';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { DangerButton } from '../components/generic/DangerButton';
import { useProjectActions } from '../hooks/project-actions';
import { pagesState, selectedProjectSelector, currentUserState, UserAllottedCredits } from '../state';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { useFormActions } from '../hooks/form-actions'

import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from '@chakra-ui/react'

type FormValues = {
  name: string;
  favicon: string;
};

export const ProjectSettingsPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const selectedProject = useRecoilValue(selectedProjectSelector);
  const [project] = useState<Project | null>(selectedProject);
  const currentUser = useRecoilValue(currentUserState);
  const userAllottedCredits = useRecoilValue(UserAllottedCredits);

  const { updateProject, deleteProject, fetchProjectPages } = useProjectActions();
  const { id } = useParams();
  const navigate = useNavigate();
  const resetPages = useResetRecoilState(pagesState);

  const [titleDisplay, setTitleDisplay] = useState(project?.title_display);
  const [titlePlacement, setTitlePlacement] = useState(project?.title_placement);
  const [titleSeparator, setTitleSeparator] = useState(project?.title_separator);

  const [titlePreview, setTitlePreview] = useState(`Home | ${project?.name}`);

  useEffect(() => {
    const preview = `${titleDisplay ? titlePlacement === 'left' ? project?.name + ' ' + titleSeparator : '' : ''} Home ${titleDisplay ? titlePlacement === 'right' ? titleSeparator + ' ' + project?.name : '' : ''}`
    setTitlePreview(preview);
  }, [titleDisplay, titlePlacement, titleSeparator])

  if (!id) return null;

  const handleSwitchChange = () => setTitleDisplay(!titleDisplay);

  const handleRadioChange = (event: any, property: string) => {
    if (property === 'placement') setTitlePlacement(event);
    else if (property === 'separator') setTitleSeparator(event);
  };

  const onUpdateProject = (data: { name: string, favicon: string }) => updateProject({ ...data, id, title_display: titleDisplay, title_placement: titlePlacement, title_separator: titleSeparator });
  const onDeleteProject = () => deleteProject(id);

  const handleGoBackToProject = () => {
    if (selectedProject) {
      // setSelectedProjectMode(selectedProject.mode); // Because it's already selected.
      resetPages();
      fetchProjectPages({ project_id: selectedProject.id });
      navigate(`/projects/${selectedProject.id}/dashboard`);
    }
  }

  return (
    <AuthenticatedLayout>
      {project && (
        <SimpleGrid gridTemplateColumns='1fr' w={{ base: 'full', md: '720px' }} mx='auto' gridGap={4}>
          <form onSubmit={handleSubmit(onUpdateProject)}>
            <SoftCard>
              <Flex h='full' flexDir='column' gap={4} justifyContent='space-between'>
                <Flex gap={4} flexDir='column'>
                  <PageHeading title="Settings" icon={MdSettings} />
                  <Flex flexDirection={{ base: 'column', md: 'row' }} gap={4}>
                    <Flex flexDir='column' flex={1} gap={6}>
                      <Box>
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <Input defaultValue={project.name} {...register('name', { required: true })} />
                        {errors?.name && <p>Name is required</p>}
                      </Box>

                      <Box>
                        <FormLabel htmlFor="favicon">Favicon</FormLabel>
                        <Input defaultValue={project.favicon} {...register('favicon')} />
                      </Box>

                      <Box>
                        <FormLabel>Display project's name in title</FormLabel>
                        <Switch size='sm' colorScheme='purple' onChange={handleSwitchChange} isChecked={titleDisplay} />
                      </Box>

                      <Flex gap={4} justifyContent='space-between'>
                        <Box>
                          <FormLabel>Placement</FormLabel>
                          <RadioGroup onChange={(e) => handleRadioChange(e, 'placement')} value={titlePlacement} size='sm' colorScheme='purple'>
                            <Stack direction='row'>
                              <Radio value='right'>Right</Radio>
                              <Radio value='left'>Left</Radio>
                            </Stack>
                          </RadioGroup>
                        </Box>

                        <Box>
                          <FormLabel>Separator</FormLabel>
                          <RadioGroup onChange={(e) => handleRadioChange(e, 'separator')} value={titleSeparator} size='sm' colorScheme='purple'>
                            <Stack direction='row'>
                              <Radio value='|'>Pipe</Radio>
                              <Radio value='-'>Dash</Radio>
                              <Radio value='–'>En Dash</Radio>
                              <Radio value='—'>Em Dash</Radio>
                            </Stack>
                          </RadioGroup>
                        </Box>

                      </Flex>
                      {/* All these things are supposed to be added to the projects table. */}
                    </Flex>
                    <Flex flexDir='column'>
                      <FormLabel>Preview</FormLabel>
                      <Button minW='280px'>
                        <Flex w='full' gap={4} alignItems='center' justifyContent='space-between'>
                          {/* <Image src={project.favicon} alt='Favicon'/> */}
                          <Flex gap={2}>
                            <Text>🔮</Text>
                            <Text>{titlePreview}</Text>
                          </Flex>
                          <Text fontSize="xs">✖</Text>
                        </Flex>
                      </Button>

                    </Flex>
                  </Flex>

                </Flex>

                <Flex gap={4} justifyContent="space-between">
                  <Button leftIcon={<FaChevronLeft />} onClick={handleGoBackToProject} size="sm" colorScheme="purple" variant="outline">
                    Go back
                  </Button>

                  <Flex alignSelf='flex-end' gap={4}>
                    <DangerButton
                      icon={<MdDelete />}
                      onClick={onDeleteProject}
                      title={'Delete Project'}
                      body={'Are you sure you want to delete this project?'}
                    />
                    <Button leftIcon={<FaSave />} size="sm" type="submit" colorScheme="purple" variant="outline">
                      Save changes
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </SoftCard >
          </form>
        </SimpleGrid>
      )}
    </AuthenticatedLayout >
  );
};
