// With inline styles support
function normalizeColor(color: string): string | null {
    const ctx = document.createElement("canvas").getContext("2d");
    if (!ctx) return null;

    ctx.fillStyle = color;
    const rgba = ctx.fillStyle;

    const rgbaMatch = rgba.match(/^rgba?\((\d+), (\d+), (\d+)(?:, ([\d.]+))?\)$/);
    if (rgbaMatch) {
        const r = parseInt(rgbaMatch[1]);
        const g = parseInt(rgbaMatch[2]);
        const b = parseInt(rgbaMatch[3]);
        return `#${((1 << 24) + (r << 16) + (g << 8) + b)
            .toString(16)
            .slice(1)
            .toUpperCase()}`;
    }
    return rgba;
}

export function extractColorPalette(document: any) {
    const colorRegex = /(#[0-9a-fA-F]{3,8}|rgba?\([^\)]+\)|hsl\(.*?\))/g;
    const originalToNormalized = new Map<string, string>();

    Array.from(document.styleSheets).forEach((stylesheet: any) => {
        try {
            Array.from(stylesheet.cssRules).forEach((rule: any) => {
                if (rule.style) {
                    const cssText = rule.style.cssText;
                    const matches = cssText.match(colorRegex);
                    
                    if (matches) {
                        matches.forEach((color: string) => {
                            const normalized = normalizeColor(color);
                            if (normalized && !originalToNormalized.has(color)) {
                                originalToNormalized.set(color, normalized);
                            }
                        });
                    }
                }
            });
        } catch (e) {
            console.warn("Unable to access stylesheet:", stylesheet.href, e);
        }
    });

    // Process inline styles
    document.querySelectorAll("*").forEach((element: any) => {
        const inlineStyle = element.getAttribute("style");
        if (inlineStyle) {
            const matches = inlineStyle.match(colorRegex);
            if (matches) {
                matches.forEach((color: string) => {
                    const normalized = normalizeColor(color);
                    if (normalized && !originalToNormalized.has(color)) {
                        originalToNormalized.set(color, normalized);
                    }
                });
            }
        }
    });


    let normalizedColors = Array.from(originalToNormalized.values());

    return {
        originalToNormalized,
        normalizedColors,
    };
}

export function overwriteColors(colorMapping: any, document: any, cleanDocument: any, originalToNormalized: any) {
    const newStyles: any = [];

    Array.from(document.styleSheets).forEach((stylesheet: any) => {
        try {
            Array.from(stylesheet.cssRules).forEach((rule: any) => {
                if (rule.style) {
                    let updatedStyles: any = [];
                    let hasChanges = false;

                    Array.from(rule.style).forEach(property => {
                        const value = rule.style.getPropertyValue(property);

                        // Direct color replacement using normalized mapping
                        const normalized = originalToNormalized.get(value.trim());
                        if (normalized && colorMapping[normalized]) {
                            updatedStyles.push(`${property}: ${colorMapping[normalized]} !important;`);
                            hasChanges = true;
                        }

                        if (value.includes('gradient')) {
                            let gradientReplacement = value;
                                                
                            for (const [originalColor, normalizedColor] of originalToNormalized.entries()) {
                                const replacementColor = colorMapping[normalizedColor];
                                if (replacementColor) {
                                    if (gradientReplacement.includes(originalColor)) {
                                        gradientReplacement = gradientReplacement.replaceAll(
                                            originalColor,
                                            replacementColor
                                        );
                                    }
                                }
                            }
                        
                            if (gradientReplacement !== value) {
                                updatedStyles.push(`${property}: ${gradientReplacement} !important;`);
                                hasChanges = true;
                            }
                        }

                        
                    });

                    if (hasChanges) {
                        newStyles.push(`${rule.selectorText} { ${updatedStyles.join(' ')} }`);
                    }
                }
            });
        } catch (e) {
            console.warn("Unable to read stylesheet:", stylesheet.href, e);
        }
    });

    // Update inline styles
    document.querySelectorAll("*").forEach((element: any) => {
        const inlineStyle = element.getAttribute("style");
        if (inlineStyle) {
            let updatedStyle = inlineStyle;
            let hasChanges = false;

            for (const [originalColor, normalizedColor] of originalToNormalized.entries()) {
                const replacementColor = colorMapping[normalizedColor];
                if (replacementColor && updatedStyle.includes(originalColor)) {
                    updatedStyle = updatedStyle.replaceAll(originalColor, replacementColor);
                    hasChanges = true;
                }
            }

            if (hasChanges) {
                element.setAttribute("style", updatedStyle);
            }
        }
    });


    if (newStyles.length > 0) {
        // Remove any existing style tag with the same ID
        const existingStyleTag = cleanDocument.getElementById("color-override-stylesheet");
        if (existingStyleTag) {
            existingStyleTag.remove();
        }

        // Create and append new style tag
        const styleTag = cleanDocument.createElement("style");
        styleTag.id = "color-override-stylesheet";
        styleTag.textContent = newStyles.join("\n");
        cleanDocument.head.appendChild(styleTag);
    }

    // if (newStyles.length > 0) {
    //     const styleTag = document.createElement("style");
    //     styleTag.id = "color-override-stylesheet";
    //     styleTag.textContent = newStyles.join("\n");
    //     document.head.appendChild(styleTag);
    // }
}

// Without inline styles support
// function normalizeColor(color: string): string | null {
//     const ctx = document.createElement("canvas").getContext("2d");
//     if (!ctx) return null;

//     ctx.fillStyle = color;
//     const rgba = ctx.fillStyle;

//     const rgbaMatch = rgba.match(/^rgba?\((\d+), (\d+), (\d+)(?:, ([\d.]+))?\)$/);
//     if (rgbaMatch) {
//         const r = parseInt(rgbaMatch[1]);
//         const g = parseInt(rgbaMatch[2]);
//         const b = parseInt(rgbaMatch[3]);
//         return `#${((1 << 24) + (r << 16) + (g << 8) + b)
//             .toString(16)
//             .slice(1)
//             .toUpperCase()}`;
//     }
//     return rgba;
// }

// export function extractColorPalette(document: any) {
//     const colorRegex = /(#[0-9a-fA-F]{3,8}|rgba?\([^\)]+\)|hsl\(.*?\))/g;
//     const originalToNormalized = new Map<string, string>();

//     Array.from(document.styleSheets).forEach((stylesheet: any) => {
//         try {
//             Array.from(stylesheet.cssRules).forEach((rule: any) => {
//                 if (rule.style) {
//                     const cssText = rule.style.cssText;
//                     const matches = cssText.match(colorRegex);
                    
//                     if (matches) {
//                         matches.forEach((color: string) => {
//                             const normalized = normalizeColor(color);
//                             if (normalized && !originalToNormalized.has(color)) {
//                                 originalToNormalized.set(color, normalized);
//                             }
//                         });
//                     }
//                 }
//             });
//         } catch (e) {
//             console.warn("Unable to access stylesheet:", stylesheet.href, e);
//         }
//     });

//     let normalizedColors = Array.from(originalToNormalized.values());

//     return {
//         originalToNormalized,
//         normalizedColors,
//     };
// }

// export function overwriteColors(colorMapping: any, document: any, originalToNormalized: any) {
//     const newStyles: any = [];

//     Array.from(document.styleSheets).forEach((stylesheet: any) => {
//         try {
//             Array.from(stylesheet.cssRules).forEach((rule: any) => {
//                 if (rule.style) {
//                     let updatedStyles: any = [];
//                     let hasChanges = false;

//                     Array.from(rule.style).forEach(property => {
//                         const value = rule.style.getPropertyValue(property);

//                         // Direct color replacement using normalized mapping
//                         const normalized = originalToNormalized.get(value.trim());
//                         if (normalized && colorMapping[normalized]) {
//                             updatedStyles.push(`${property}: ${colorMapping[normalized]} !important;`);
//                             hasChanges = true;
//                         }

//                         if (value.includes('gradient')) {
//                             let gradientReplacement = value;
                                                
//                             for (const [originalColor, normalizedColor] of originalToNormalized.entries()) {
//                                 const replacementColor = colorMapping[normalizedColor];
//                                 if (replacementColor) {
//                                     if (gradientReplacement.includes(originalColor)) {
//                                         gradientReplacement = gradientReplacement.replaceAll(
//                                             originalColor,
//                                             replacementColor
//                                         );
//                                     }
//                                 }
//                             }
                        
//                             if (gradientReplacement !== value) {
//                                 updatedStyles.push(`${property}: ${gradientReplacement} !important;`);
//                                 hasChanges = true;
//                             }
//                         }

                        
//                     });

//                     if (hasChanges) {
//                         newStyles.push(`${rule.selectorText} { ${updatedStyles.join(' ')} }`);
//                     }
//                 }
//             });
//         } catch (e) {
//             console.warn("Unable to read stylesheet:", stylesheet.href, e);
//         }
//     });

//     if (newStyles.length > 0) {
//         const styleTag = document.createElement("style");
//         styleTag.id = "color-override-stylesheet";
//         styleTag.textContent = newStyles.join("\n");
//         document.head.appendChild(styleTag);
//     }
// }