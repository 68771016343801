export const plans = {
    STARTER_MONTHLY: {
        title: 'Starter',
        description: 'A plan that fits your customers basic needs',
        price: 49.99,
        features: [
            '1 Website',
            '1-Click website creation',
            '2 languages translation per site',
            'Publish website',
            'Build on mobile',
            'Access templates',
            'Creative mode (1 page)',
            'Guided ai mode',
            'SSL certificate',
            { a: '100 form submissions', b: 'For personal or small websites with low interaction.' },
            { a: '1 GB/month of bandwidth', b: 'For very low traffic sites with up to 500 visitors/month.' },
            'Starter prompts',
            'Model 1'
        ]
    },
    PROFESSIONAL_MONTHLY: {
        title: 'Professional',
        description: 'A plan that fits most of your customers needs',
        price: 99.99,
        features: [
            '3 Websites',
            '1-Click website creation',
            '3 languages translation per site',
            'Publish website',
            'Voice mode',
            'Generate ai images',
            'Build on mobile',
            'Developer mode',
            'Styles enhancer',
            'Access templates',
            'Creative mode (3 pages)',
            'Guided ai mode',
            'SSL certificate',
            { a: '2,500 form submissions', b: 'For small businesses and medium-traffic sites.' },
            { a: '2 GB/month of bandwidth', b: 'For sites expecting up to 1200 visitors/month.' },
            'Starter prompts',
            'Model 1, 2 and 4.'
        ]
    },
    ELITE_MONTHLY: {
        title: 'Elite',
        description: 'A plan that fits all your customers needs',
        price: 199.99,
        features: [
            '5 Websites',
            '1-Click website creation',
            '5 languages translation per site',
            'Publish website',
            'Voice mode',
            'Generate ai images',
            'Build on mobile',
            'Developer mode',
            'Export project',
            'Styles enhancer',
            'Access templates',
            'Creative mode (5 pages)',
            'Guided ai mode',
            'SSL certificate',
            { a: '10,000 form submissions', b: 'For active business websites with higher interaction.' },
            { a: '5 GB/month of bandwidth', b: 'For more active sites with up to 3000 visitors/month.' },
            'Starter prompts',
            'Access all 4 models.'
        ]
    },
    STARTER_ANNUAL: {
        title: 'Starter',
        description: 'A plan for businesses who are just starting out.',
        price: 39.99,
        amount_saved: 120,
        features: [
            '1 Website',
            '1-Click website creation',
            '2 languages translation per site',
            'Publish website',
            'Build on mobile',
            'Access templates',
            'Creative mode (1 page)',
            'Guided ai mode',
            'SSL certificate',
            { a: '100 form submissions', b: 'For personal or small websites with low interaction.' },
            { a: '1 GB/month of bandwidth', b: 'For very low traffic sites with up to 500 visitors/month.' },
            'Starter prompts',
            'Model 1'
        ]
    },
    PROFESSIONAL_ANNUAL: {
        title: 'Professional',
        description: 'A plan that will have you covered for most of your business needs.',
        price: 79.99,
        amount_saved: 240,
        features: [
            '3 Websites',
            '1-Click website creation',
            '3 languages translation per site',
            'Publish website',
            'Voice mode',
            'Generate ai images',
            'Build on mobile',
            'Developer mode',
            'Styles enhancer',
            'Access templates',
            'Creative mode (3 pages)',
            'Guided ai mode',
            'SSL certificate',
            { a: '2,500 form submissions', b: 'For small businesses and medium-traffic sites.' },
            { a: '2 GB/month of bandwidth', b: 'For sites expecting up to 1200 visitors/month.' },
            'Starter prompts',
            'Model 1, 2 and 4.'
        ]
    },
    ELITE_ANNUAL: {
        title: 'Elite',
        description: 'A plan for big enterprises.',
        price: 169.99,
        amount_saved: 360,
        features: [
            '5 Websites',
            '1-Click website creation',
            '5 languages translation per site',
            'Publish website',
            'Voice mode',
            'Generate ai images',
            'Build on mobile',
            'Developer mode',
            'Export project',
            'Styles enhancer',
            'Access templates',
            'Creative mode (5 pages)',
            'Guided ai mode',
            'SSL certificate',
            { a: '10,000 form submissions', b: 'For active business websites with higher interaction.' },
            { a: '5 GB/month of bandwidth', b: 'For more active sites with up to 3000 visitors/month.' },
            'Starter prompts',
            'Access all 4 models.'
        ]
    }
};