import ky, { HTTPError } from 'ky';
import jwt_decode from 'jwt-decode';
import { Project, ProjectInvitation, User, StripeSession, Plan, Page, Form } from '../../types';
import snakecaseKeys from 'snakecase-keys';

export type AccessToken = {
  exp: number;
  sub: string;
};


type UploadUrl = {
  newurl: string;
};

type CreateSiteResponse = {
  site: any;
};

type DeploySiteResponse = {
  deployment: any;
};

// type SaveSiteResponse = {
//   site: any;
// };

type FetchSiteResponse = {
  site: any;
};

type GetProjectFormsResponse = {
  forms: any;
};

type GetUserFormsResponse = {
  forms: any;
}

type FetchSitesResponse = {
  sites: any;
};

type GetTranscribeResponse = {
  transcription: string;
};

type EnhanceCSSResponse = {
  enhanced_css: string;
}

type GetCompletionsResponse = {
  completions: string;
}

type GetColorPaletteResponse = {
  color_palette: string;
}

type GetPageTranslationResponse = {
  translation: any;
}

type GetProjectTranslationResponse = {
  translations: any;
}

type GetProjectAutomationResponse = {
  automations: any;
}

type TriggerFunctionCallResponse = {
  function_call: any;
}

type GetImageGenerationResponse = {
  image_url: string;
}

type GetIndexResponse = {
  message: string[];
};

type GetMeResponse = {
  me: User;
};

type ListProjectsResponse = {
  projects: Project[];
};

type ListPagesResponse = {
  pages: Page[];
}

type GetProjectResponse = {
  project: Project;
};

type CreateProjectResponse = {
  project: Project;
  source_file: string;
};

type CreatePageResponse = {
  page: Page;
};

type EnableFormResponse = {
  form: Form;
  additionalForms: Form[];
}

type UpdateProjectResponse = {
  project: Project;
};

type GetProjectUsersResponse = {
  users: User[];
};

type CreateProjectInvitationResponse = {
  project_invitation: ProjectInvitation;
};

type AcceptProjectInvitationResponse = {
  accepted: boolean;
};

type ApiErrorInterface = {
  name: string;
  message: string;
  status: number;
};

type CreateStripeSessionResponse = {
  stripe_session: StripeSession;
};

type CreateStripeBillingPortalSessionResponse = {
  stripe_billing_portal_session: StripeSession;
};

type StripePlansReponse = {
  plans: Plan[];
};

type TokenResponse = {
  access_token: string;
  expires_in: number;
};

type fetchPageContentResponse = {
  content: string;
};

type fetchAllPagesContentsResponse = {
  contents: any[];
};

type fetchConversationHistoryResponse = {
  conversation_history: any[];
};

type fetchUserPreferencesResponse = {
  user_preferences: any;
};

type fetchChatResponse = {
  chat: any[];
};


type fetchUndoHistoryResponse = {
  undo_history: any[];
};

type fetchLinksResponse = {
  links: any[];
};

type fetchFormsResponse = {
  forms: any[];
};

type fetchImagesResponse = {
  images: any[];
};

export class ApiError extends Error implements ApiErrorInterface {
  public readonly name: string;
  public readonly status: number;

  constructor({ name, message, status }: { name: string; message: string; status: number }) {
    super(message);
    this.name = name;
    this.status = status;
  }
}

export class ApiClient {
  private static prefixUrl = process.env.REACT_APP_API_URL;

  static client = ky.create({
    prefixUrl: this.prefixUrl,
    credentials: 'include',
    mode: 'cors',
  });

  static tokenClient = ApiClient.client.extend({
    hooks: {
      beforeRequest: [
        async (request) => {
          const accessToken = localStorage.getItem('access_token');
          const sessionId = localStorage.getItem('session_id');

          if (accessToken) {
            const token = this.tokenNeedsRefresh(accessToken) ? await this.refreshToken() : accessToken;
            request.headers.set('authorization', `Bearer ${token}`);
            request.headers.set('session_id', sessionId ? sessionId : '');
          } else {
            return new Response();
          }
        },
      ],
    },
  });

  private static async refreshToken(): Promise<string> {
    try {
      const { access_token } = await this.client.post('authn/refresh').json<TokenResponse>();
      return access_token;
    } catch (error) {
      console.error("Error during refresh:", error);
      throw error;
    }
  }

  private static tokenNeedsRefresh(accessToken: string | null) {
    if (!accessToken) {
      return true;
    }

    const decoded = jwt_decode<AccessToken>(accessToken);
    const expires = new Date(decoded.exp);

    const isAboutToExpire = expires.getTime() * 1000 + 10000 < new Date().getTime();
    return isAboutToExpire;
  }

  private static isCustomApiError(error: Record<string, unknown>) {
    return ['name', 'message'].every((item) => error.hasOwnProperty(item));
  }

  private static async handleApiError<T>(action: Promise<T>): Promise<T> {
    try {
      const res = await action;
      return res;
    } catch (err: unknown) {
      if (err instanceof HTTPError) {
        const body = await err.response.json();
        if (body && this.isCustomApiError(body)) {
          throw new ApiError({ name: body.name, message: body.message, status: err.response.status });
        }
      }

      throw new ApiError({
        name: 'UNKNOWN_ERROR',
        message: 'An unknown error occurred, please try again later',
        status: 500,
      });
    }
  }

  static async register(firstName: string, lastName: string, email: string, password: string): Promise<TokenResponse> {
    return this.handleApiError(
      this.client
        .post('authn/register', { json: { first_name: firstName, last_name: lastName, email, password } })
        .json<TokenResponse>()
    );
  }

  static async authnPassword(email: string, password: string): Promise<TokenResponse> {
    return this.handleApiError(this.client.post('authn/password', { json: { email, password } }).json<TokenResponse>());
  }

  static async authnSocial(email: string, firstName: string, lastName: string): Promise<TokenResponse> {
    return this.handleApiError(this.client.post('authn/social', { json: { email, firstName, lastName } }).json<TokenResponse>());
  }

  static async resetPassword(email: string): Promise<void> {
    await this.handleApiError(this.client.post('authn/reset-password', { json: { email } }));
  }

  static async verifyResetPassword(password: string, resetToken: string): Promise<void> {
    await this.handleApiError(
      this.client.post('authn/reset-password/verify', { json: { password, reset_token: resetToken } })
    );
  }

  static async verifyUser(verifyToken: string): Promise<void> {
    await this.handleApiError(this.client.post('authn/verify-user', { json: { verify_token: verifyToken } }));
  }

  static async resendVerification(): Promise<void> {
    await this.handleApiError(this.tokenClient.post('authn/resend-verification'));
  }

  static async signOut(): Promise<void> {
    await this.handleApiError(this.client.post('authn/sign-out'));
  }

  static async getMe(): Promise<GetMeResponse> {
    return this.handleApiError(this.tokenClient.get('me').json<GetMeResponse>());
  }

  static async getProjects(): Promise<Project[]> {
    const { projects } = await this.handleApiError(this.tokenClient.get('projects').json<ListProjectsResponse>());
    return projects;
  }

  static async createProject({ name, selectedProjectMode, favicon, fromTemplate }: { name: string, selectedProjectMode: string, favicon: string, fromTemplate: boolean }): Promise<any> {

    // console.log("######### fromTemplate (api-client): ", fromTemplate);

    const { project, source_file } = await this.handleApiError(
      this.tokenClient.post('projects', { json: { name, mode: selectedProjectMode, favicon, from_template: fromTemplate } }).json<CreateProjectResponse>()
    );

    return {
      project,
      source_file
    };
  }

  static async getProject(id: string): Promise<Project> {
    const { project } = await this.handleApiError(this.tokenClient.get(`projects/${id}`).json<GetProjectResponse>());
    return project;
  }

  static async updateProject({ id, name, favicon, title_display, title_placement, title_separator }: Partial<Project>): Promise<Project> {
    const { project } = await this.handleApiError(
      this.tokenClient.put(`projects/${id}`, { json: { name, favicon, title_display, title_placement, title_separator } }).json<UpdateProjectResponse>()
    );
    return project;
  }

  static async deleteProject(id: string): Promise<void> {
    await this.tokenClient.delete(`projects/${id}`);
  }

  static async getProjectUsers(id: string): Promise<User[]> {
    const { users } = await this.handleApiError(
      this.tokenClient.get(`projects/${id}/users`).json<GetProjectUsersResponse>()
    );
    return users;
  }

  static async deleteProjectUser(projectId: string, userId: string): Promise<void> {
    await this.tokenClient.delete(`projects/${projectId}/users/${userId}`);
  }

  static async createProjectInvitation({
    projectId,
    toUserEmail,
  }: {
    projectId: string;
    toUserEmail: string;
  }): Promise<ProjectInvitation> {
    const { project_invitation } = await this.handleApiError(
      this.tokenClient
        .post('project-invitations', { json: { project_id: projectId, to_user_email: toUserEmail } })
        .json<CreateProjectInvitationResponse>()
    );
    return project_invitation;
  }

  static async acceptProjectInvitation({ key }: { key: string }): Promise<AcceptProjectInvitationResponse> {
    return this.handleApiError(this.tokenClient.get(`project-invitations/${key}`).json());
  }

  static async createStripeSession({ planId }: { planId: string }): Promise<StripeSession> {
    const { stripe_session } = await this.handleApiError(
      this.tokenClient.post('stripe/sessions', { json: { plan_id: planId } }).json<CreateStripeSessionResponse>()
    );

    return stripe_session;
  }

  static async createStripeBillingPortalSession(): Promise<StripeSession> {
    const { stripe_billing_portal_session } = await this.handleApiError(
      this.tokenClient.post('stripe/billing-portal-sessions').json<CreateStripeBillingPortalSessionResponse>()
    );

    return stripe_billing_portal_session;
  }

  static async listStripePlans(): Promise<Plan[]> {
    const { plans } = await this.handleApiError(this.tokenClient.get('stripe/plans').json<StripePlansReponse>());

    return plans;
  }

  static async initUserPreferences(userId: string, userPreferences: any): Promise<void> {
    await this.handleApiError(this.tokenClient.post(`user/user-preferences/init/${userId}`, { json: { user_preferences: userPreferences }, timeout: false }));
  }

  static async updateUserPreferences(userId: string, userPreferences: any): Promise<void> {
    await this.handleApiError(this.tokenClient.post(`user/user-preferences/${userId}`, { json: { user_preferences: userPreferences }, timeout: false }));
  }
  static async initFreeTrial(userId: string, planId: any): Promise<void> {
    await this.handleApiError(this.tokenClient.post(`user/initFreeTrial`, { json: { userId, planId } }));
  }

  static async fetchUserPreferences(userId: string): Promise<any[]> {
    const { user_preferences } = await this.handleApiError(
      this.tokenClient.get(`user/user-preferences/${userId}`, { timeout: false }).json<fetchUserPreferencesResponse>()
    );

    return user_preferences;
  }

  static async sendSupportMessage(message: string): Promise<void> {
    await this.handleApiError(this.tokenClient.post('communications/support', { json: { message } }));
  }

  static async createCloudFrontSite(userId: string, projectId: string, name: string): Promise<any> {
    const { site } = await this.handleApiError(
      this.tokenClient.post('sites/create', { json: { userId, projectId, name } }).json<CreateSiteResponse>()
    );
    return site;
  }

  static async deployCloudFrontSite(formData: any): Promise<any> {
    const { deployment } = await this.handleApiError(
      this.tokenClient.post(`sites/deploy`, { body: formData, timeout: false }).json<DeploySiteResponse>()
    );
    return deployment;
  }
  static async updateCloudFrontSite(formData: any): Promise<any> {
    const { deployment } = await this.handleApiError(
      this.tokenClient.post(`sites/update`, { body: formData, timeout: false }).json<DeploySiteResponse>()
    );
    return deployment;
  }

  static async saveCloudFrontSite(site_id: string, user_id: string, project_id: string, name: string, url: string): Promise<void> {
    await this.handleApiError(
      this.tokenClient.post(`sites/save`, { json: { siteId: site_id, userId: user_id, projectId: project_id, name, url } })
    );
  }

  static async deleteCloudFrontSite(site_id: string, project_id: string): Promise<void> {
    await this.tokenClient.delete(`sites/delete/${site_id}`, { json: { projectId: project_id } });
  }

  static async fetchCloudFrontSite(project_id: string): Promise<any> {
    const { site } = await this.handleApiError(
      this.tokenClient.post(`sites/fetch/${project_id}`).json<FetchSiteResponse>()
    );
    return site;
  }

  static async fetchCloudFrontSites(user_id: string): Promise<any> {
    const { sites } = await this.handleApiError(
      this.tokenClient.post(`sites/fetch-all/${user_id}`).json<FetchSitesResponse>()
    );
    return sites;
  }

  static async transcribe(formData: any): Promise<string> {
    const { transcription } = await this.handleApiError(
      this.tokenClient.post(`transcribe`, { body: formData, timeout: false }).json<GetTranscribeResponse>()
    );

    return transcription;
  }

  static async triggerFunctionCall(prompt: any): Promise<string> {
    const { function_call } = await this.handleApiError(
      this.tokenClient.post(`function-call`, { json: { prompt }, timeout: false }).json<TriggerFunctionCallResponse>()
    );
    return function_call;
  }

  static async getCompletions(messages: any, options?: any): Promise<string> {
    const { completions } = await this.handleApiError(
      this.tokenClient.post(`completions`, { json: { messages, options }, timeout: false }).json<GetCompletionsResponse>()
    );
    return completions;
  }

  static async getColorPalette(originalPalette: any, themeColor: any): Promise<string> {
    const { color_palette } = await this.handleApiError(
      this.tokenClient.post(`color-palette`, { json: { colorPalette: originalPalette, themeColor }, timeout: false }).json<GetColorPaletteResponse>()
    );
    return color_palette;
  }

  static async getPageTranslation(localizationMap: any, language: string): Promise<string> {
    const { translation } = await this.handleApiError(
      this.tokenClient.post(`localization/page`, { json: { localizationMap, language }, timeout: false }).json<GetPageTranslationResponse>()
    );
    return translation;
  }

  static async getProjectTranslation(localizationMaps: any, language: string): Promise<string> {
    const { translations } = await this.handleApiError(
      this.tokenClient.post(`localization/project`, { json: { localizationMaps, language }, timeout: false }).json<GetProjectTranslationResponse>()
    );
    return translations;
  }

  static async getTemplateAutomation(templateMaps: any, businessName: string, businessDescription: string): Promise<string> {
    const { automations } = await this.handleApiError(
      this.tokenClient.post(`automation/project`, { json: { templateMaps, business_name: businessName, business_description: businessDescription }, timeout: false }).json<GetProjectAutomationResponse>()
    );
    return automations;
  }

  static async getImageGeneration(prompt: string, options?: any): Promise<string> {
    const { image_url } = await this.handleApiError(
      this.tokenClient.post(`image-generation`, { json: { prompt, options }, timeout: false }).json<GetImageGenerationResponse>()
    );
    return image_url;
  }

  static async enhanceCSS(html: string, css: string, options?: any): Promise<string> {
    const { enhanced_css } = await this.handleApiError(
      this.tokenClient.post(`enhance-css`, { json: { html, css, options }, timeout: false }).json<EnhanceCSSResponse>()
    );
    return enhanced_css;
  }

  static async getIndexes(): Promise<string[]> {
    const response = await this.handleApiError(
      this.tokenClient.get(`index`).json<GetIndexResponse>()
    );
    return response.message;
  }

  // static async updateProjectPages(projectId: string, pages: Page[]): Promise<void> {
  static async updateProjectPages(pages: Page[]): Promise<void> {
    // await this.tokenClient.post(`projects/${projectId}/pages/update`, { json: { pages } });
    await this.tokenClient.post(`pages/update`, { json: { pages }, timeout: false });
  }

  static async updateProjectCommonElements(projectId: string, commonElements: any[]): Promise<void> {
    await this.tokenClient.post(`projects/${projectId}/common-elements/update`, { json: { projectId, commonElements } });
  }

  static async fetchProjectPages(projectId: string): Promise<Page[]> {
    const { pages } = await this.handleApiError(this.tokenClient.get(`projects/${projectId}/pages`, { timeout: false }).json<ListPagesResponse>());
    return pages;
  }

  static async createPage({ projectId, title, path, content, conversationHistory, chat, links, images, forms, locale, isIndex }: { projectId: string, title: string, path: string, content: string, conversationHistory: any[], chat: any[], links: any, images: any, forms: any, locale: any, isIndex?: boolean }): Promise<Page> {
    const { page } = await this.handleApiError(
      this.tokenClient.post('pages', { json: { projectId, title, path, content, conversationHistory, chat, links, images, forms, locale, isIndex: isIndex || false } }).json<CreatePageResponse>()
    );

    const parsedPage = {
      ...page,
      chat: page.chat.map((c: string) => JSON.parse(c)),
      conversation_history: page.conversation_history.map((s: string) => JSON.parse(s)),

      // Are the following necessary?
      links: page.links.map((l: string) => snakecaseKeys(JSON.parse(l))),
      images: page.images.map((i: string) => snakecaseKeys(JSON.parse(i))),
      forms: page.forms.map((f: string) => snakecaseKeys(JSON.parse(f))),
    }

    console.log(
      'Here is the parsed page:',
      parsedPage
    );

    return parsedPage;
  }

  static async deletePage(id: string): Promise<void> {
    await this.tokenClient.delete(`pages/${id}`);
  }

  static async renamePage({ page_id, new_title }: { page_id: string, new_title: string }): Promise<void> {
    await this.handleApiError(
      this.tokenClient.post(`pages/title/${page_id}`, { json: { newTitle: new_title } })
    );
  }

  static async updatePageContent({ page_id, page_content }: { page_id: string, page_content: string }): Promise<void> {
    await this.handleApiError(
      this.tokenClient.post(`pages/content/${page_id}`, { json: { pageContent: page_content } })
    );
  }

  static async updatePageConversationHistory({ page_id, conversation_history }: { page_id: string, conversation_history: any[] }): Promise<void> {
    await this.handleApiError(
      this.tokenClient.post(`pages/conversation-history/${page_id}`, { json: { conversationHistory: conversation_history } })
    );
  }

  static async fetchPageContent(page_id: string): Promise<string> {
    const { content } = await this.handleApiError(
      this.tokenClient.get(`pages/content/${page_id}`).json<fetchPageContentResponse>()
    );
    return content;
  }

  static async fetchAllPagesContents(project_id: string): Promise<any[]> {
    const { contents } = await this.handleApiError(
      this.tokenClient.get(`pages/contents/${project_id}`).json<fetchAllPagesContentsResponse>()
    );
    return contents;
  }

  static async fetchConversationHistory(page_id: string): Promise<any[]> {
    const { conversation_history } = await this.handleApiError(
      this.tokenClient.get(`pages/conversation-history/${page_id}`).json<fetchConversationHistoryResponse>()
    );

    return conversation_history;
  }

  static async fetchChat(page_id: string): Promise<any[]> {
    const { chat } = await this.handleApiError(
      this.tokenClient.get(`pages/chat/${page_id}`).json<fetchChatResponse>()
    );

    return chat;
  }

  static async updateChat({ page_id, chat }: { page_id: string, chat: any[] }): Promise<void> {
    await this.handleApiError(
      this.tokenClient.post(`pages/chat/${page_id}`, { json: { chat: chat } })
    );
  }

  static async fetchUndoHistory(page_id: string): Promise<any[]> {
    const { undo_history } = await this.handleApiError(
      this.tokenClient.get(`pages/undo-history/${page_id}`).json<fetchUndoHistoryResponse>()
    );
    return undo_history;
  }

  static async fetchLinks(page_id: string): Promise<any[]> {
    const { links } = await this.handleApiError(
      this.tokenClient.get(`pages/links/${page_id}`).json<fetchLinksResponse>()
    );

    return links;
  }

  static async updateLinks({ page_id, links }: { page_id: string, links: any[] }): Promise<void> {
    await this.handleApiError(
      this.tokenClient.post(`pages/links/${page_id}`, { json: { links: links } })
    );
  }

  static async fetchForms(page_id: string): Promise<any[]> {
    const { forms } = await this.handleApiError(
      this.tokenClient.get(`pages/forms/${page_id}`).json<fetchFormsResponse>()
    );

    return forms;
  }

  static async updateForms({ page_id, forms }: { page_id: string, forms: any[] }): Promise<void> {
    await this.handleApiError(
      this.tokenClient.post(`pages/forms/${page_id}`, { json: { forms: forms } })
    );
  }

  static async fetchImages(page_id: string): Promise<any[]> {
    const { images } = await this.handleApiError(
      this.tokenClient.get(`pages/images/${page_id}`).json<fetchImagesResponse>()
    );

    return images;
  }

  static async updateImages({ page_id, images }: { page_id: string, images: any[] }): Promise<void> {
    await this.handleApiError(
      this.tokenClient.post(`pages/images/${page_id}`, { json: { images: images } })
    );
  }

  static async fetchUnsplash(searchTerm: string, page: number | null): Promise<any> {
    const response = await this.handleApiError(
      this.tokenClient.post('fetch-unsplash', { json: { searchTerm, page } }).json()
    );
    return response;
  }
  static async unsplashTrack(link: string): Promise<any> {
    const response = await this.handleApiError(
      this.tokenClient.post('track-unsplash', {
        json: { downloadLocation: link }
      }).json()
    );
    return response;
  }

  static async uploadToS3(formData: any): Promise<string> {
    const { newurl } = await this.handleApiError(
      this.tokenClient.post('upload-s3', { body: formData, timeout: false }).json<UploadUrl>()
    );
    return newurl;
  }

  // Forms

  static async enableForm({ userId, projectId, pageId, domId, destinationEmail }: {
    userId: string,
    projectId: string,
    pageId: string,
    domId: string,
    destinationEmail: string
  }): Promise<{ form: Form, additionalForms: Form[] }> {
    const response = await this.handleApiError(
      this.tokenClient.post('forms/enable-form', {
        json: { userId, projectId, pageId, domId, destinationEmail }
      }).json<EnableFormResponse>()
    );
    console.log("api-client.ts: ", response)
    return response;
  }

  static async getUserForms(user_id: string): Promise<any> {
    const { forms } = await this.handleApiError(
      this.tokenClient.get(`forms/get-user-forms/${user_id}`).json<GetUserFormsResponse>()
    );

    return forms;
  }

  static async getProjectForms(project_id: string): Promise<any> {
    const { forms } = await this.handleApiError(
      this.tokenClient.get(`forms/get-project-forms/${project_id}`).json<GetProjectFormsResponse>()
    );

    return forms;
  }

  static async toggleFormStatus(form_id: string): Promise<any> {
    await this.handleApiError(
      this.tokenClient.post(`forms/toggle-form-status/${form_id}`)
    );
  }

  static async deleteForm(form_id: string): Promise<void> {
    await this.tokenClient.delete(`forms/delete-form/${form_id}`);
  }

  static async deleteUser(id: string): Promise<{ status: string }> {
    const response = await this.tokenClient.delete(`users/${id}`);

    const data: any = await response.json();

    console.log("Parsed API Client Response:", data);

    return data; // Return the entire data object
  }
}
