import React, { useEffect, useState } from 'react';
import {
  Heading,
  Button,
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  VStack,
  useColorModeValue,
  Card,
  Link,
  Box,
  Flex,
  List,
  ListItem,
  ListIcon,
  Container,
  Badge,
  Switch,
  Center,
  Divider
} from '@chakra-ui/react';
import { AuthenticatedLayout } from '../layouts';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../state';
import { PageHeading } from '../components/generic/PageHeading';
import { useSubscriptionActions, useUserActions } from '../hooks';
import { MdCheckCircle } from 'react-icons/md';
import { Plan, User } from '../types';
import { useNavigate } from 'react-router-dom';
import { plans } from './plans';

export const PricingCard = ({
  plan,
  onActivate,
  user,
}: {
  plan: Plan;
  onActivate: (id: string) => Promise<void>;
  user: User;
}) => {
  const accentColor = useColorModeValue('purple.600', 'purple.200');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  if (!(plan.id in plans)) return null;
  // @ts-ignore
  const { features, title, price, amount_saved } = plans[plan.id as keyof typeof plans];

  return (
    <Card rounded="lg" py={4} px={6} w="280px" boxShadow="md" borderWidth={1} borderColor={borderColor}>
      <VStack spacing={4} align="stretch">
        {amount_saved && (
          <Badge colorScheme="purple" alignSelf="start">
            Save ${amount_saved}
          </Badge>
        )}
        <Heading size="md">{title}</Heading>
        <Flex align="baseline">
          <Text fontSize="3xl" fontWeight="bold" color={accentColor}>
            ${price}
          </Text>
          <Text fontSize="sm" color="gray.500" ml={1}>
            /mo
          </Text>
        </Flex>
        <Button colorScheme="purple" size="sm" onClick={() => onActivate(plan.id)} variant="outline">
          Subscribe
        </Button>
       <List spacing={2}>
  {features.map((feature: any, index: number) => (
    <ListItem key={index} fontSize="sm">
      <Flex alignItems="flex-start">
        <ListIcon as={MdCheckCircle} color={accentColor} mt={1} />
        <Box>
          {feature && feature.a ? (
            <>
              <Text>{feature.a}</Text>
              <Text fontSize="xs" color="gray.500">
                {feature.b}
              </Text>
            </>
          ) : (
            feature
          )}
        </Box>
      </Flex>
    </ListItem>
  ))}
</List>
      </VStack>
    </Card>
  );
};

export const CustomPricingCard = ({
  onActivateCustom,
}: {
  onActivateCustom: () => Promise<void>;
}) => {
  const accentColor = useColorModeValue('purple.600', 'purple.200');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Card rounded="lg" py={4} px={6} w="280px" boxShadow="md" borderWidth={1} borderColor={borderColor}>
      <VStack spacing={4} align="stretch">
        <Badge colorScheme="purple" alignSelf="start">
          Personalized
        </Badge>
        <Heading size="md">Custom</Heading>
        <Flex align="baseline">
          <Text fontSize="3xl" fontWeight="bold" color={accentColor}>
            $xxx
          </Text>
          <Text fontSize="sm" color="gray.500" ml={1}>
            /mo
          </Text>
        </Flex>
        <Link href="https://sifo.ai/contact-us" isExternal>
          <Button colorScheme="purple" size="sm" w="full" variant="outline">
            Contact Us
          </Button>
        </Link>
        <Text fontSize="sm">
          If you have specific needs, contact us to discuss a customized offer that best fits you.
        </Text>
      </VStack>
    </Card>
  );
};

export const SubscribePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const user = useRecoilValue(currentUserState);
  const { activateSubscription, listPlans } = useSubscriptionActions();
  const [plans, setPlans] = useState<Plan[]>([]);
  const { initFreeTrial } = useUserActions();
  const [isAnnual, setIsAnnual] = useState(true);

  useEffect(() => {
    const getPlans = async () => {
      const data = await listPlans();
      setPlans(data || []);
    };
    getPlans();
  }, [listPlans]);

  const onActivate = async (id: string) => {
    try {
      const session = await activateSubscription(id);
      if (session) window.location.replace(session.url);
    } catch (error) {
      console.error(error);
    }
  };

  const onActivateCustom = async () => {
    // Implementation for custom activation
  };

  const onActivateFreeTrial = async () => {
    if (user) {
  //    initFreeTrial(user.id, 'FREE_TRIAL');
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.has_active_subscription) navigate('/');
  }, [user, navigate]);

  return (
    <AuthenticatedLayout sidebar={false}>
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          {process.env.REACT_APP_STRIPE_TEST_MODE === '1' && (
            <Alert status="info" colorScheme="purple">
              <AlertIcon />
              <AlertTitle>Test payments enabled</AlertTitle>
              <AlertDescription>
                Test mode for payments is enabled and no real charges will be made. The checkout will be prefilled with a
                test card that you can use for testing purposes.
              </AlertDescription>
            </Alert>
          )}
          {searchParams.get('status') === 'warning' && (
            <Alert status="warning">
              <AlertIcon />
              <AlertTitle>Payment failed</AlertTitle>
              <AlertDescription>Something went wrong while processing your payment. Please try again.</AlertDescription>
            </Alert>
          )}

          <Center textAlign="center">
            <VStack>
              <PageHeading
                title={!user?.free_trial_activated ? `Welcome to SIFO${user ? `, ${user.first_name}` : ''}!` : `Welcome back${user ? `, ${user.first_name}` : ''}!`}
                size="xl"
              />
              <Text fontSize="xl" color="gray.500" mt={2}>
                Start with the plan that suits you the most!
              </Text>
            </VStack>
          </Center>

          <Center>
            <Button
              onClick={onActivateFreeTrial}
              size="md"
              colorScheme="purple"
              variant="outline"
            >
              Try SIFO for Free
            </Button>
          </Center>
          <Flex align="center" justify="center">
            <Divider flex="1" />
            <Text px={4} fontWeight="bold" color="gray.500">
              OR
            </Text>
            <Divider flex="1" />
          </Flex>
          <Box>
            <Flex justify="center" align="center" mb={6}>
              <Box
                borderWidth={1}
                borderColor={isAnnual ? 'gray.300' : 'purple.500'}
                bg={isAnnual ? 'transparent' : 'purple.500'}
                color={isAnnual ? 'inherit' : 'white'}
                px={3}
                py={1}
                borderRightRadius={0}
                cursor="pointer"
                onClick={() => setIsAnnual(false)}
              >
                Monthly
              </Box>
              <Box
                borderWidth={1}
                borderColor={isAnnual ? 'purple.500' : 'gray.300'}
                bg={isAnnual ? 'purple.500' : 'transparent'}
                color={isAnnual ? 'white' : 'inherit'}
                px={3}
                py={1}
                borderLeftRadius={0}
                cursor="pointer"
                onClick={() => setIsAnnual(true)}
              >
                Annual
              </Box>
            </Flex>

            <Flex justifyContent="center" flexWrap="wrap" gap={6}>
              {user &&
                plans
                  .slice(isAnnual ? 3 : 0, isAnnual ? undefined : 3)
                  .map((plan) => (
                    <PricingCard key={plan.id} plan={plan} onActivate={onActivate} user={user} />
                  ))}
              <CustomPricingCard key="custom" onActivateCustom={onActivateCustom} />
            </Flex>
          </Box>
        </VStack>
      </Container>
    </AuthenticatedLayout>
  );
};