import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserPreferences, currentUserState } from '../../state';
import { 
    Tooltip, 
    useBreakpointValue, 
    Checkbox, 
    Button, 
    IconButton, 
    useDisclosure, 
    useColorModeValue,
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalContent, 
    ModalHeader, 
    Flex, 
    Text, 
    Heading, 
    ModalOverlay, 
    ModalCloseButton, 
    Box, 
    Grid 
} from '@chakra-ui/react';
import { 
    MdGTranslate, 
    MdOutlineCode, 
    MdFormatListBulletedAdd, 
    MdUndo, 
    MdAccountTree, 
    MdOutlineLineWeight, 
    MdOutlineRestore, 
    MdHelp, 
    MdChevronRight 
} from 'react-icons/md';
import { RiVoiceprintFill } from "react-icons/ri";
import { TbMessageCode } from "react-icons/tb";
import { IoLinkSharp, IoSparkles } from "react-icons/io5";
import { FaFileExport, FaRegImage } from "react-icons/fa";
import { BsRocketTakeoff } from "react-icons/bs";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

export const Tips: React.FC<any> = ({ full }) => {
    const isWideScreen = useBreakpointValue({ base: false, md: true });
    const [userPreferences, setUserPreferences] = useRecoilState(UserPreferences);
    const { isOpen: isTipsModalOpen, onOpen: onTipsModalOpen, onClose: onTipsModalClose } = useDisclosure();

    // Theme-aware colors
    const modalBg = useColorModeValue('white', 'gray.800');
    const modalColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const boxBg = useColorModeValue('white', 'gray.700');
    const iconButtonBg = useColorModeValue('gray.100', 'gray.700');
    const iconButtonHoverBg = useColorModeValue('gray.200', 'gray.600');

    const handleCheckboxOnChange = () => {
        setUserPreferences((prev: any) => ({
            ...prev,
            display_tips_modal_on_projects_list: !prev.display_tips_modal_on_projects_list
        }));
    }

    useEffect(() => {
        if (!full) return;
        if (window.location.href.endsWith('/') && userPreferences?.display_tips_modal_on_projects_list) {
            onTipsModalOpen();
        }
    }, []);

    const bounceAnimation = {
        y: [0, -10, 0],
        transition: {
            duration: 1,
            repeat: Infinity,
        }
    };

    const toolbarItems = [
        { text: "Clear content and start again.", icon: <MdOutlineRestore />, label: "Start From Scratch" },
        { text: "Use your voice to create.", icon: <RiVoiceprintFill />, label: "Stop recording" },
        { text: "Toggle pages panel.", icon: <MdOutlineLineWeight />, label: "Pages" },
        { text: "See an outline of your project's elements.", icon: <MdAccountTree />, label: "Outliner" },
        { text: "Use text to create.", icon: <TbMessageCode />, label: "Chat" },
        { text: "Toggle undo history panel.", icon: <MdUndo />, label: "Undo" },
        { text: "Toggle links and navigation panel.", icon: <IoLinkSharp />, label: "Links" },
        { text: "Toggle forms panel.", icon: <MdFormatListBulletedAdd />, label: "Forms" },
        { text: "Toggle images panel.", icon: <FaRegImage />, label: "Images" },
        { text: "Toggle developer mode panel.", icon: <MdOutlineCode />, label: "Developer Mode" },
        { text: "Instantly enhance your project with a click.", icon: <IoSparkles />, label: "Enhance" },
        { text: "Translate your project into different languages.", icon: <MdGTranslate />, label: "Translate" },
        { text: "Publish and share your project on the web.", icon: <BsRocketTakeoff />, label: "Deploy" },
        { text: "Export project locally and host anywhere.", icon: <FaFileExport />, label: "Export" },
    ];

    const tips = [
        { title: "Specificity is Key", content: "Provide precise descriptions for enhanced visual uniqueness." },
        { title: "Embrace Details", content: "SIFO thrives on comprehensive information. Don't hesitate to provide extensive details in your requests." },
        { title: "Leverage Long Prompts", content: "Our starter prompts are intentionally lengthy. Aim for similar depth in your own prompts for optimal results." },
        { title: "Explore and Innovate", content: "SIFO's versatility allows for boundless creativity. Experiment with various concepts to unlock its full potential." }
    ];

    return (
        <>
            <Tooltip 
                label='Tips: A quick guide to SIFO.' 
                hasArrow 
                placement='bottom-end' 
                isDisabled={!userPreferences?.display_tooltips}
            >
                <IconButton
                    size="xs"
                    variant="ghost"
                    icon={<MdHelp />}
                    onClick={onTipsModalOpen}
                    aria-label="Tutorial"
                    
                />
            </Tooltip>

            <Modal 
                scrollBehavior="inside" 
                isOpen={isTipsModalOpen} 
                onClose={onTipsModalClose} 
                size={{ base: "full", md: "4xl" }} 
                isCentered
            >
                <ModalOverlay />
                <ModalContent maxH="90vh" bg={modalBg} color={modalColor}>
                    <ModalHeader>
                        <Heading as="h1" size="xl" textAlign="center" fontWeight="bold">
                            Prompting Tips
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {isWideScreen ? (
                            <Flex direction="column" alignItems="center">
                                <Box width="100%" maxWidth="1200px">
                                    <Box position="relative" paddingTop="52%" mb={4}>
                                        <video
                                            autoPlay
                                            loop
                                            muted
                                            playsInline
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                        >
                                            <source src="https://i.imgur.com/CI9BDhG.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <Flex
                                            position="absolute"
                                            bottom="0"
                                            left="0"
                                            right="0"
                                            justifyContent="center"
                                            alignItems="center"
                                            pb={2}
                                        >
                                            <motion.div animate={bounceAnimation}>
                                                <Box
                                                    bg="purple.500"
                                                    color="white"
                                                    borderRadius="full"
                                                    p={2}
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        const modalBody = document.querySelector('.chakra-modal__body');
                                                        modalBody?.scrollTo({
                                                            top: modalBody.scrollHeight,
                                                            behavior: 'smooth'
                                                        });
                                                    }}
                                                >
                                                    <ChevronDownIcon boxSize={6} />
                                                </Box>
                                            </motion.div>
                                        </Flex>
                                    </Box>
                                    <Grid templateColumns="1fr 1fr" gap={4}>
                                        {tips.map((tip, index) => (
                                            <Box
                                                key={index}
                                                borderWidth={1}
                                                borderColor={borderColor}
                                                borderRadius="md"
                                                p={3}
                                                boxShadow="sm"
                                                bg={boxBg}
                                            >
                                                <Heading size="md" mb={2} textAlign="center">
                                                    {tip.title}
                                                </Heading>
                                                <Text fontSize="sm">{tip.content}</Text>
                                            </Box>
                                        ))}
                                    </Grid>
                                </Box>
                            </Flex>
                        ) : (
                            <Flex justifyContent="space-between" gap={4} flexDir="column">
                                {toolbarItems.map((item, index) => (
                                    <Flex key={index} gap={2} justifyContent="space-between" alignItems="center">
                                        <Text>{item.text}</Text>
                                        <IconButton
                                            size="sm"
                                            variant="ghost"
                                            aria-label={item.label}
                                            icon={item.icon}
                                            bg={iconButtonBg}
                                            _hover={{ bg: iconButtonHoverBg }}
                                        />
                                    </Flex>
                                ))}
                            </Flex>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <Flex w='full' justifyContent="space-between" gap={2}>
                            <Checkbox
                                size="sm"
                                onChange={handleCheckboxOnChange}
                                colorScheme="purple"
                                isChecked={userPreferences?.display_tips_modal_on_projects_list}
                            >
                                Always show tips
                            </Checkbox>
                            <Button
                                size="sm"
                                colorScheme="purple"
                                rightIcon={<MdChevronRight />}
                                onClick={onTipsModalClose}
                                variant="outline"
                            >
                                Got it
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};