import React from 'react';
import { useRecoilValue } from "recoil";
import { ProjectCommonElementsParsed } from "../../state";
import { Flex, Heading, Button, IconButton } from '@chakra-ui/react';
import { SoftCard } from '../../components/generic/SoftCard';
import { BsReverseLayoutSidebarInsetReverse } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import '../../lib/anim.css';

export const CommonElements: React.FC<any> = () => {

  const projectCommonElementsParsed = useRecoilValue(ProjectCommonElementsParsed);

  const handleRemoveElement = (commonElementId: string) => {
    return;
  }

  const CommonElement: React.FC<any> = ({ elem }) => {
    return (
      <Flex flexDir='column' gap={2} flex={1}>
        <Flex gap={1} justifyContent='space-between'>
          <Button variant="outline" justifyContent='flex-start' flexGrow={1} size='xs' colorScheme='purple'>
            {elem.tagName.toLowerCase() + "#" + elem.id}
          </Button>
          <IconButton size='xs' aria-label='Remove' colorScheme='purple' variant='ghost' onClick={() => handleRemoveElement(elem.id)} icon={<MdDeleteOutline />} />
        </Flex>
      </Flex>
    );
  }

  return (
    <SoftCard h='100%' minH='0' flex={1}>
      <Flex className='zoom' flexDir='column' gap={4} h='full'>
        <Flex alignItems='end' gap={2}>
          <BsReverseLayoutSidebarInsetReverse />
          <Heading as='h5' size='sm'>
            Common Elements
          </Heading>
        </Flex>

        <Flex flexDir='column' gap={2} overflowY='auto' css={{ "::-webkit-scrollbar": { display: "none" } }}>
          {projectCommonElementsParsed && projectCommonElementsParsed.map((child: any, index: number) => (
            <CommonElement key={index} elem={child} />
          ))}
        </Flex>
      </Flex>
    </SoftCard>
  );
};
